import { Card } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReportItem = ({ onClick, title }) => (
    <p
        className="px-2 text-[15px] my-1 hover:text-purple-800 cursor-pointer"
        onClick={onClick}
    >
        {title}
    </p>
);

export default function Report() {
    const navigate = useNavigate();

    return (
        <div className="p-4">
            <div className="grid grid-cols-3 gap-5">
                <div className="col-span-1">
                    <Card
                        className="min-h-[300px] h-full border shadow-md shadow-[grey]"
                        title={<span style={{ color: '#26004d' }}>Voucher Report</span>}
                        type='inner'
                        size='small'
                    >
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/sales-report')} title="Sale" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/purchases-report')} title="Purchase" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/expenses')} title="Expense" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/delivery-challan-report')} title="Delivery Challan" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/purchase-order-report')} title="Purchase Order" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/quotation-report')} title="Quotation" />
                        </div>
                    </Card>
                </div>

                <div className="col-span-1">
                    <Card
                        className="min-h-[300px] h-full border shadow-md shadow-[grey]"
                        title={<span style={{ color: '#26004d' }}>Party Report</span>}
                        type='inner'
                        size='small'
                    >
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/ledger')} title="Party Ledger" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/daybook')} title="Day Book" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/payments')} title="Cash and Bank Report" />
                        </div>
                    </Card>
                </div>

                <div className="col-span-1">
                    <Card
                        className="min-h-[300px] h-full border shadow-md shadow-[grey]"
                        title={<span style={{ color: '#26004d' }}>Inventory</span>}
                        type='inner'
                        size='small'
                    >
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/item-wise-report')} title="Item wise Report (item list / then invoice)" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/product/item')} title="Items / Stocks" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/TcsPayable')} title="TCS Payable" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/TcsReceivable')} title="TCS Receivable" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/TdsPayable')} title="TDS Payable" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/TdsReceivable')} title="TDS Receivable" />
                        </div>
                    </Card>
                </div>

                <div className="col-span-1">
                    <Card
                        className="min-h-[300px] h-full border shadow-md shadow-[grey] "
                        // style={{backgroundColor:'red'}}
                        title={<div style={{ color: '#26004d' }}>GST Report</div>}
                        type='inner'
                        size='small'
                    >
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/gst-gstr1')} title="GST R1" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/gst-gstr2')} title="GST R2" />
                        </div>
                        <div className="p-[2px] border-gray-300 shadow-sm rounded-lg my-2 shadow-purple-300 transition-shadow duration-200 hover:bg-purple-100">
                            <ReportItem onClick={() => navigate('/reports/gst-gstr3')} title="GST R3" />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
