import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Card, Select, DatePicker } from 'antd';
import DynamicTable from '../../tables/DynamicTable';
import { DownOutlined, CalendarOutlined, ShareAltOutlined, ArrowDownOutlined, PrinterOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import Parties from '../parties/Parties';
import { useDispatch, useSelector } from 'react-redux';
import { getLedgers } from './actions';
import AppListHeader from '../../header/AppListHeader';
import * as XLSX from 'xlsx';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';
import { getParties } from '../actions';

const { RangePicker } = DatePicker;

function Ledger({ companyDetail }) {
  const dispatch = useDispatch();
  const ledgersResponse = useSelector(state => state.ledgers.getLedgers.response);
  const partyResponse = useSelector(state => state.parties.getParties.response);
  
  const [filterQuery, setFilterQuery] = useState({ party__id: companyDetail?.id });

  useEffect(() => {
    getLedgerData();

    if (
      partyResponse?.party_count === partyResponse?.parties?.results?.length &&
      partyResponse?.parties?.results?.length > 0
    ) {
      // Parties already loaded
    } else {
      dispatch(getParties());
    }
  }, [dispatch, filterQuery]);

  const getLedgerData = async () => {
    await dispatch(getLedgers(filterQuery));
  };

  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Company Name', accessor: 'company_name' },
    { Header: 'Invoice No.', accessor: 'inv_no' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Voucher', accessor: 'voucher_type' },
    { Header: 'Party Type', accessor: 'party_type' },
    { Header: 'Amount', accessor: 'amount' },
  ];

  const tableColumns = columns.map((ledger) => ({
    title: ledger.Header,
    dataIndex: ledger.accessor,
    key: ledger.accessor,
  }));

  const preparedLedgers = Array.isArray(ledgersResponse?.ledgers) ? 
    ledgersResponse.ledgers
      .filter((ledger) => {
        if (filterQuery.voucher_type) {
          return ledger.voucher_type === filterQuery.voucher_type;
        }
        return true; // No filter applied
      })
      .map((ledger) => ({
        party: ledger.party,
        date: ledger.date,
        inv_no: ledger.purchase_inv_no || ledger.sale_inv_no || ledger.id,
        company_name: ledger.company_name,
        amount: ledger.amount,
        party_type: ledger.party_type || '-',
        voucher_type: ledger.sale ? 'Sale' :
                      ledger.purchase ? 'Purchase' :
                      ledger.expense ? 'Expense' :
                      ledger.payment_in ? 'Payment In' :
                      ledger.payment_out ? 'Payment Out' :
                      '-',
      })) 
  : []; // Default to an empty array if ledgersResponse.ledgers is not an array

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const wsData = preparedLedgers?.map((e, index) => [
      index + 1,
      e.date,
      e.inv_no,
      e.company_name || '-',
      e.amount,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        'S.No.',
        'Date',
        'Invoice No.',
        'Company Name',
        'Amount',
      ],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, `Ledger_${companyDetail?.company_name || ''}`);
    XLSX.writeFile(workbook, `Ledger_${companyDetail?.company_name || ''}.xlsx`);
  };


  /**
   * filter for changes
   */
  const handleVoucherTypeChange = (type) => {
    setFilterQuery(prev => ({
      ...prev,
      voucher: type,
    }));
  };

  const handlePartyChange = (party) => {
    setFilterQuery(prev => ({
      ...prev,
      party__id: party,
    }));
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setFilterQuery(prev => ({
        ...prev,
        date: dateStrings[0],
        date_lte: dateStrings[1],
      }));
    } else {
      setFilterQuery(prev => ({
        ...prev,
        date: null,
        date_lte: null,
      }));
    }
  };

  return (
    <div>
      {!companyDetail && (
        <AppListHeader
          headerContent='Ledger Report'
          excel={true}
          handleExcelExport={handleExportToExcel}
          print={true}
          download={false}
          cancel={true}
          printNavigation='/ListPrint'
          printData={{ tableColunm: tableColumns, tableData: preparedLedgers, name: 'Party Ledger List' }}
        />
      )}

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        {!companyDetail && (
          <Select
            placeholder="Select Party"
            className='w-[250px] m-1'
            onChange={handlePartyChange}
            allowClear
            showSearch
            optionFilterProp='children'
          >
            {partyResponse?.parties?.results?.map((data) => (
              <Select.Option key={data.id} value={data.id}>{data.company_name}</Select.Option>
            ))}
          </Select>
        )}

        <Select
          placeholder="Select Voucher Type"
          className='w-[250px] m-1'
          onChange={handleVoucherTypeChange}
          allowClear
        >
          <Select.Option value='sale'>Sale</Select.Option>
          <Select.Option value='purchase'>Purchase</Select.Option>
          <Select.Option value='payment_in'>Payment In</Select.Option>
          <Select.Option value='payment_out'>Payment Out</Select.Option>
          <Select.Option value='expense'>Expense</Select.Option>
        </Select>
        
        <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
      </div>

      {companyDetail ? (
        <Card title={`${companyDetail?.company_name}`} size="small" className="border-2 border-gray-300">
          <div className="flex justify-between">
            <div className="p-2 text-sm">
              To,<br />
              {companyDetail?.company_name}<br />
              {companyDetail?.address}, {companyDetail?.city}, {companyDetail?.state}, {companyDetail?.zip_code}<br />
              {companyDetail?.mobile_no}<br />
              {companyDetail?.gst_no}
            </div>
            <Card className="border-2 border-gray-300 p-2 text-sm">
              Current Balance<br />
              <hr className="my-1 border-gray-400" />
              <p>{companyDetail?.current_balance}</p>
            </Card>
          </div>
          <br />
          <DynamicTable data={preparedLedgers} tableColumns={tableColumns} />
        </Card>
      ) : (
        <DynamicTable data={preparedLedgers} tableColumns={tableColumns} />
      )}
    </div>
  );
}

export default Ledger;
