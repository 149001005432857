import React, { useEffect, useState } from 'react';
import { AudioOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, DatePicker, Select } from 'antd'; // Corrected import
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PaymentOutList from './PaymentOutList';
import { getPaymentOut, getPaymentOutSuccess } from './actions';
import * as XLSX from 'xlsx';
import AppListHeader from '../../header/AppListHeader';
import { getParties } from '../../party/actions';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';

const { Search } = Input;
const { RangePicker } = DatePicker;


export default function ItemList() {
  const dispatch = useDispatch();

  const paymentOutDatas = useSelector(state => state.paymentOut.getPaymentOuts.response);
  const partyResponse = useSelector(state => state.parties.getParties.response)
  const paymentOutDeleteResponse = useSelector(state => state.paymentOut.deletePaymentOut.response);

  const navigate = useNavigate();
  const [filterQuery, setFilterQuery] = useState(null)
  const [paymentsoutDetail, setPaymentsOutDetail] = useState('')   // state for set the searched value
  // state for filter query
  const [partySearchText, setPartySearchText] = useState('')   // state for set the searched value




  useEffect(() => {
    getPaymentsOutData();

    /**
     * handle party state
     */
    if (
      partyResponse?.party_count == partyResponse?.parties?.results?.length &&
      partyResponse?.parties?.results?.length > 0
    ) {

    }
    else {
      dispatch(getParties())
    }
    /**
     * end of handling party state
     */
  }, [dispatch, partySearchText, filterQuery, paymentOutDeleteResponse]);

  const getPaymentsOutData = async () => {

    if (partySearchText) {
      await dispatch(getPaymentOut({ search: partySearchText }));
    }

    else if (filterQuery) {
      await dispatch(getPaymentOut(filterQuery));
    }

    else {
      const response = await dispatch(getPaymentOut());

      if (response) {
        setPaymentsOutDetail({
          ...paymentsoutDetail,
          partiesCount: response.party_count,
          toCollect: response.total_to_collect_balance,
          toPay: response.total_to_pay_balance,
        });
      }
    }
  };


  const handleTableChange = async (pagination, filters, sorter) => {
    setFilterQuery({
      ...filterQuery,
      ordering: sorter?.order == 'ascend' ?
        `${sorter?.field}`
        :
        sorter?.order == 'descend' ?
          `-${sorter?.field}`
          :
          null,
    })
  }

  const handlePartySearch = (value) => {
    setPartySearchText(value)
  };

  const handleCreatePaymentOut = () => {
    navigate('/purchase/create-payment-out');
  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Assuming paymentOutDatas is the list you want to export
    const wsData = paymentOutDatas?.payment_outs?.results?.map((e, index) => [
      index + 1,
      e.date, // Replace with correct date format if necessary
      e.party_company_name,
      e.remark,
      e.payment_mode,
      e.amount,
    ]);


    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        'S.No.',
        'Date',
        'Party name',
        'Description',
        'Payment Mode',
        'Amount',
      ],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Payments_out');
    XLSX.writeFile(workbook, 'Payments_out.xlsx');
  };


  /**
   * to change the filter
   */
  const handlePaymentModeChange = (type) => {
    setFilterQuery({
      ...filterQuery,
      voucher_type: type
    })
  }

  const handlePartyChange = (party) => {
    setFilterQuery({
      ...filterQuery,
      party__id: party
    })
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setFilterQuery({
        ...filterQuery,
        date: dateStrings[0],
        date_lte: dateStrings[1]
      })
    } else {
      setFilterQuery({
        ...filterQuery,
        date: null,
        date_lte: null
      })
    }
  };
  /**
   * end of changing the filter
   */

  const columns = [
    { title: 'Date', dataIndex: 'date' },
    { title: 'Company name', dataIndex: 'party_company_name' },
    { title: 'Description', dataIndex: 'remark' },
    { title: 'Payment Mode', dataIndex: 'payment_mode' },
    { title: 'Amount', dataIndex: 'amount' }
  ];

  return (
    <div className=''>
      <AppListHeader
        headerContent='Payment Outs'
        creationLable="Create New Payment Out"
        handleCreateNew={handleCreatePaymentOut}
        excel={true}
        handleExcelExport={handleExportToExcel}
        print={true}
        download={true}
        cancel={true}
        printNavigation='/ListPrint'
        printData={{ tableColunm: columns, tableData: paymentOutDatas?.payment_outs?.results, name: 'Payment Out List' }}
      />

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        <Select
          placeholder="Select Party"
          className='w-[250px] m-1'
          onChange={handlePartyChange}
          allowClear
          showSearch
          optionFilterProp='children'
        >
          {partyResponse?.parties?.results?.map((data, id) => (
            <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
          ))}
        </Select>

        <Select
          placeholder="Payment Mode"
          className='w-[250px] m-1'
          onChange={handlePaymentModeChange}
          allowClear
        >
          <Select.Option value='CASH'>CASH</Select.Option>
          <Select.Option value='BANK'>BANK</Select.Option>
          <Select.Option value='UPI'>UPI</Select.Option>
          <Select.Option value='CARD'>CARD</Select.Option>
        </Select>
        <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
      </div>

      <PaymentOutList
        paymentOutDatas={paymentOutDatas?.payment_outs?.results}
        handleTableChange={handleTableChange}
      />
    </div>
  );
}
