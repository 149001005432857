import React, { useEffect } from 'react';
import { Card, Table, Select } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { getLedgers } from '../party/ledger/actions';
import { getDashboardData } from './actions';

// const barData = [
//   { name: 'January', sale: 500000, purchase: 800000, expense: 30000 },
//   { name: 'Fab', sale: 500000, purchase: 800000, expense: 30000 },
//   { name: 'March', sale: 500000, purchase: 800000, expense: 30000 },
// ];

const pieData = [
  { name: 'Group A', value: 10 },
  { name: 'Group B', value: 15 },
  { name: 'Group C', value: 20 },
];

const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

function Dashboard() {

  const dispatch = useDispatch()
  const dashboardData = useSelector(state => state.dashboard.getDashboardData.response)

  useEffect(() => {
    // if (!dashboardData) {
      dispatch(getDashboardData())
    // }
  }, [dispatch])


  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Party Name', accessor: 'company_name' },
    { Header: 'Voucher', accessor: 'voucher' },
    { Header: 'Amount', accessor: 'amount' }
  ];
  
  const StockColumn = [
    { Header: 'Product', accessor: 'item_name' },
    { Header: 'Quantity', accessor: 'quantity' },
  ];
  

  const recentActions = dashboardData?.ledgers?.map(data => {
    return {
      company_name: data?.company_name ? data?.company_name : '-',
      amount: data?.amount,
      voucher: data?.sale
        ? 'Sale'
        : data?.purchase
          ? 'Purchase'
          : data?.payment_in
            ? 'Payment In'
            : data?.expense
              ? 'Expense'
              : 'Payment Out'
    };
  });

  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor
  }));
  
  const stockTableColumns = StockColumn.map(column => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor
  }));
  const stockrecentActions = dashboardData?.top_five_low_stock_items?.map(data => ({
    item_name: data?.item_name ? data?.item_name : '-', 
    quantity: data?.current_qty, 
  }));


  // const barData = [
  //   { name: 'January', sale: 500000, purchase: 800000, expense: 30000 },
  //   { name: 'Fab', sale: 500000, purchase: 800000, expense: 30000 },
  //   { name: 'March', sale: 500000, purchase: 800000, expense: 30000 },
  // ];


  return (
    <div className="bg-lavender min-h-screen">
      <div className="flex justify-evenly rounded-xl gap-4 border border-white w-full h-1/5 bg-white p-4">
        <Card size='small' className="w-[18%] p-1 bg-lavender shadow-md border-[orange] shadow-[orange]">
          <p className='text-[17px] text-[orange]'><b>{dashboardData?.total_sale}</b></p>
          <p className='text-[15px]'>Total Sales</p>
        </Card>
        <Card size='small' className="w-[18%] p-1 bg-lavender shadow-md border border-[#fa638a] shadow-[#fa638a]">
          <p className='text-[17px] text-[#fa638a]'><b>{dashboardData?.total_purchase}</b></p>
          <p className='text-[15px]'>Total Purchase</p>
        </Card>
        <Card size='small' className="w-[18%] p-1 bg-lavender shadow-md border border-[red] shadow-[red]">
          <p className='text-[17px] text-[red]'><b>{dashboardData?.total_payment_out}</b></p>
          <p className='text-[15px]'>Payment Sent</p>
        </Card>
        <Card size='small' className="w-[18%] p-1 bg-lavender shadow-md border border-[green] shadow-[green]">
          <p className='text-[17px] text-[green]'><b>{dashboardData?.total_payment_in}</b></p>
          <p className='text-[15px]'>Payment Received</p>
        </Card>
        <Card size='small' className="w-[18%] p-1 bg-lavender shadow-md border border-[blue] shadow-[blue]">
          <p className='text-[17px] text-[blue]'><b>{dashboardData?.total_cash_and_bank_balance}</b></p>
          <p className='text-[15px]'>Bank+Cash</p>
        </Card>
      </div>

      <div className="flex justify-between gap-4 p-4 h-2/5">
        <div className="w-1 bg-white">
          <h3 className='w-[50vw] mb-2'><b>Sales and Purchase</b></h3>
          <BarChart
            width={1000}
            height={200}
            // data={barData}
            data={dashboardData?.monthly_data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="sale" fill="#8884d8" name="Sale" />
            <Bar dataKey="purchase" fill="#82ca9d" name="Purchase" />
            <Bar dataKey="expense" fill="#ffc658" name="Expense" />
          </BarChart>
        </div>
        {/* <div className="w-1/2 bg-white">
          <PieChart width={400} height={230}>
            <Pie
              data={pieData}
              cx={200}
              cy={100}
              labelLine={false}
              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div> */}
      </div>

      <div className="flex gap-4">
        <Card className="w-2/3 h-1/3 bg-lavender">
          <p>Recent Invoice</p>
          <Table
            size='small'
            columns={tableColumns}
            dataSource={recentActions}
            pagination={false}
          />
        </Card>
        <Card className="w-1/3 p-4 bg-white">
          <div className="flex justify-between">
            <h3>Top 5 Lowest Stock</h3>
            {/* <Select
              showSearch
              placeholder="Select days"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: '1', label: 'Last 7 Days' },
                { value: '2', label: 'Last 3 Months' },
                { value: '3', label: 'Last Year' },
              ]}
            /> */}
          </div>
          <Table
            size='small'
            columns={stockTableColumns}
            dataSource={stockrecentActions} 
            pagination={false}
          />
        </Card>



      </div>

      <div className="p-4 flex gap-4">
        {/* <Card className="w-3/4  bg-lavender">
          <p>Payment Received</p>
        </Card> */}
        {/* <Card className="w-1/3  bg-white">
          <h3>Stock History</h3>
          <Card className="w-48  bg-lavender">
            <h3>Total Sales Item</h3>
          </Card>
          <Card className="w-48 bg-lavender">
            <h3>Total Sales Return Item</h3>
          </Card>
          <Card className="w-48  bg-lavender">
            <h3>Total Purchase Item</h3>
          </Card>
          <Card className="w-48  bg-lavender">
            <h3>Purchase Return Item</h3>
          </Card>
        </Card> */}

        <div className="flex justify-between gap-4 p-4 h-2/5">
          <div className="w-1 bg-white">
            <h3 className='w-[50vw] mb-2'><b>Payament Sent and Received</b></h3>
            <BarChart
              width={1000}
              height={200}
              // data={barData}
              data={dashboardData?.monthly_payment_data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="payment_sent" fill="#8884d8" name="Payment Sent" />
              <Bar dataKey="payment_received" fill="#82ca9d" name="Payment Received" />
              {/* <Bar dataKey="expense" fill="#ffc658" /> */}
            </BarChart>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Dashboard;
