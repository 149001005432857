import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';

export default function ContactMap() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            // once: true,
        });
    }, []);

    return (
        <section id="contact" className="md:p-6 p-4 drop-shadow">
            <div className="max-w-10xl mx-auto p-6 rounded-lg shadow-lg grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col justify-center text-center" data-aos="fade-up">
                    <p className="text-lg md:px-20 px-2">
                        2nd floor, Sahu Complex, Near Disha College, Ram Nagar Kota Road, Raipur (C.G.)
                    </p>
                    <p className="text-red-600 mt-4">
                        <i className="fas fa-phone-alt"></i> +91 86400-91593
                    </p>
                    <p className="text-red-600 mt-2">
                        <i className="fas fa-envelope"></i> info@yashvitech.com
                    </p>
                    <p className="text-red-600 mt-2">
                        <i className="fas fa-clock"></i> Mon-Fri - 10:00 AM - 06:00 PM
                    </p>
                </div>
                <div className="flex justify-center" data-aos="fade-up">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d929.6253889932643!2d81.61016726952187!3d21.251605248685333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDE1JzA1LjgiTiA4McKwMzYnMzguOSJF!5e0!3m2!1sen!2sin!4v1728037810120!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </section>
    );
}
