import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Table } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const DynamicTable = ({ columns, data, tableColumns, handleTableChange }) => {


  return (
    <div
      // className="overflow-scroll scrollbar-thin scrollbar-thumb-red-500
      // scrollbar-track-orange-200 mt-4
      // "
    >
      <Table
        size='small'
        columns={tableColumns}
        dataSource={data}
        bordered
        pagination={false}
        // pagination={{
        //   pageSize: 2,
        // }}
        // scroll={{
        //   y: 240,
        // }}
        onChange={handleTableChange}
        rowKey={(record, index) => index} // Assuming data array is not uniquely keyed
      // onRow={(record) => ({
      //   onClick: () => handleView(record),
      // })}
      />
    </div>
  );

};

DynamicTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DynamicTable;
