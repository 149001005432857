import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Table } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { customTable } from '../../../settings/codeSettings/additionalMethods';

const PurchaseDashboardTable = ({ columns, data, tableColumns, handleTableChange }) => {

  /**
   * Data to show inside the table (sale items)
   */
  const childTableColumns = [
    { title: 'Item Name', dataIndex: 'item_name' },
    { title: 'Hsn No', dataIndex: 'item_hsn_code' },
    { title: 'Qty', dataIndex: 'qty' },
    { title: 'Rate', dataIndex: 'rate' },
    { title: 'Total', dataIndex: 'total' },
    { title: 'Gst Amount', dataIndex: 'gst_amount' },
    { title: 'Amount', dataIndex: 'amount' },
  ];

  return (
    <div className="overflow-scroll scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-orange-200 mt-4">
      <Table
        size='small'
        // scroll={{
        //   y: 300,
        // }}
        columns={tableColumns}
        dataSource={data}
        bordered
        pagination={false}
        onChange={handleTableChange}
        rowKey={(record, index) => index} // Assuming data array is not uniquely keyed
        onRow={(record) => ({
          // onClick: () => handleView(record),
          style: {backgroundColor: record?.payment_status === 'paid' ? '#e6ffe6' : ''}
        })}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {customTable(childTableColumns, record?.purchase_items)}
            </>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        expandedRowClassName={() => 'bg-[#f5f5f0]'}
      />
    </div>
  );
};

PurchaseDashboardTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PurchaseDashboardTable;
