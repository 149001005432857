import React from 'react';
import logo from '../../../images/logo.png';
import { Button, Col, Table, Row, Divider } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation } from 'react-router-dom';
import { loggedInUser } from '../../../settings/codeSettings/additionalMethods';
import secureLocalStorage from 'react-secure-storage';

export default function Invoice() {
    const location = useLocation();
    const record = location.state?.record || {};
    console.log(record)

    // Use record for dynamic data
    const {
        delivery_challan = {},
        billDetail = {},
        customerDetail = {}
    } = record;

    const basicAmount = billDetail.basicAmount || 1000;
    const taxAmount = (basicAmount * 18) / 100;
    const billAmount = basicAmount + taxAmount;

    const printInvoice = () => {

        window?.print();
    };

    const columns = [
        { title: 'ITEM', dataIndex: 'item_name', key: 'item_name' },
        { title: 'QTY', dataIndex: 'qty', key: 'qty' },
        { title: 'RATE', dataIndex: 'rate', key: 'rate' },
        { title: 'DISCOUNT', dataIndex: 'discount', key: 'discount' },
        { title: 'GST', dataIndex: 'gst_amount', key: 'gst_amount' },
        { title: 'AMOUNT', dataIndex: 'amount', key: 'amount' },
    ];

    const user = JSON.parse(secureLocalStorage.getItem("loggedInUser")) || {};

console.log(user,"usser")
    return (
        <Content className="p-8 bg-white shadow-lg rounded-lg">
            <div className="invoice-content">
                <div className="overflow-auto w-[100%] m-auto px-2">
                    {/* <div className='flex flex-col justify-center bg-[lightgray] items-center my-2'>
                        <h2 className='w-fit font-semibold text-[30px] mt-2'>Delivery Challan Invoice</h2>
                        <div className='bg-[lightgray] w-[100%]'>
                            <img
                                src={logo}
                                alt="Logo"
                                className='w-[20%] h-[60px] m-auto p-2'
                            />
                        </div>
                    </div> */}

                   
                            <div style={{ display: 'flex',justifyContent:'space-between' }}>
                                <div style={{ height:'22vh'}}>
                                     <img
                            src={user.company_logo || logo}
                            alt="Logo"
                            className="h-20"
                        />
                                    <p style={{ fontSize: 'large', fontWeight: 'bold' }}>Delivery Challan</p>

                                </div>
                                <div style={{textAlign:'right'}}>
                                    <h1 style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>{user?.company_name}</h1>
                                    <p style={{ fontSize: 'large' }}>
                                        {user?.address && user?.address + ','}
                                        {user?.city && user?.city + ','}
                                        {user?.state && user?.state + ','}
                                        {user?.zip_code && user?.zip_code}
                                    </p>
                                    <p>Mobile Number: {user?.mobile_number}</p>
                                    <p>Email: {user?.email}</p>
                                    <p style={{ fontSize: 'large', fontWeight: 'bold' }}>GST Number: {user?.gst}</p>
                                </div>
                            </div>
                        
                    <Divider className='my-2' />

                    <div className='py-3'>

                        <div className='py-3'>
                            <Row gutter={16} align="middle">
                                <Col span={13} className='text-start'>
                                    <strong>Quotation Number:</strong> {record?.delivery_challan_no}
                                </Col>
                                <Col span={11} className='text-start'>
                                    <strong>Date:</strong> {record?.date}
                                </Col>
                            </Row>
                        </div>

                        {/* <div className='flex'>
                            <div className='w-[35%] text-start'>Purchase Order Number</div>- {record?.delivery_challan_no}
                        </div>
                        <div className='flex'>
                            <div className='w-[35%] text-start'> Date</div>- {record.date}
                        </div> */}
                    </div>
                    <div className='flex flex-col sm:flex-row justify-between mt-3'>
                        <div className='w-[95%] sm:w-[45%]'>
                            <div className='bg-[lightgray] font-semibold px-2'>Billed From</div>
                            <div className='px-2'>Company Name - {loggedInUser?.company_name}</div>
                            <div className='px-2'>Address - {loggedInUser?.address}</div>
                            <div className='px-2'>City - {loggedInUser?.city}</div>
                            <div className='px-2'>State - {loggedInUser?.state}</div>
                            <div className='px-2'>Zip Code - {loggedInUser?.zip_code}</div>
                            <div className='px-2'>Phone - {loggedInUser?.mobile_number}</div>
                            <div className='px-2'>GST - {loggedInUser?.gst}</div>
                        </div>
                        <div className='w-[95%] sm:w-[45%]'>
                            <div className='bg-[lightgray] font-semibold px-2'>Billed To</div>
                            <div className='px-2'>Company Name - {record?.billed_to_company_name || ''}</div>
                            <div className='px-2'>Address - {record?.billed_to_address || ''}</div>
                            <div className='px-2'>City - {record?.city || ''}</div>
                            <div className='px-2'>State - {record?.state || ''}</div>
                            <div className='px-2'>Zip Code - {record?.zip_code || ''}</div>
                            <div className='px-2'>Phone - {record?.mobile_number || ''}</div>
                            <div className='px-2'>GST - {record?.gst || ''}</div>
                        </div>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={record?.delivery_challan_items || []}
                        pagination={false}
                        className="rounded-lg border border-gray-300 mb-6"
                        // footer={footer}
                        size="small"
                        rowClassName="hover:bg-gray-50"
                    />
                    <Row>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <table className="table-auto w-full text-left">
                                <tbody>
                                    <tr>
                                        <td className='p-2 font-medium'>SUB TOTAL</td>
                                        <td className='p-2'>₹ {record?.sub_total}</td>
                                    </tr>
                                    <tr>
                                        <td className='p-2 font-medium'>GST Amount</td>
                                        <td className='p-2'>₹ {record?.gst_amount}</td>
                                    </tr>
                                    <tr>
                                        <td className='p-2 font-medium'>Shipping Charges</td>
                                        <td className='p-2'>₹ {record?.shipping_charge}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                                    </tr>
                                    <tr>
                                        <th className='p-2 font-semibold'>TOTAL AMOUNT</th>
                                        <th className='p-2 font-semibold'>₹ {record?.total_amount}</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <div className='w-[100%] flex my-5'>
                    </div>
                </div>

                <style>
                    {`
                    @media print {
                        body * {
                            visibility: hidden;
                        }
                        .invoice-content, .invoice-content * {
                            visibility: visible;
                        }
                        .invoice-content {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                        }
                    }
                    `}
                </style>
                <Button
                    type="primary"
                    onClick={printInvoice}
                    className="mt-6 block mx-auto"
                >
                    Print Invoice
                </Button>
            </div>
        </Content>
    );
}
