import React, { useEffect, useState } from 'react';
import { DownOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, Menu, Modal, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import CategoryContained from '../category/CategoryContained';
import UnitContained from '../unit/UnitContained';
import ItemList from './ItemList';
import { useDispatch, useSelector } from 'react-redux';
import { getItems } from './actions';
import { handleSearchText } from '../../../settings/codeSettings/additionalMethods';
import AppListHeader from '../../header/AppListHeader';

const { Search } = Input;

export default function ItemDashboard() {

    const dispatch = useDispatch();
    const itemsResponse = useSelector(state => state.items.getItems.response);

    const [itemSearchText, setItemSearchText] = useState('')
    const [filterQuery, setFilterQuery] = useState(null)   // state for filter query
    const [paymentOutDetail, setPaymentOutDetail] = useState('')

    useEffect(() => {
        getItemsData();
    }, [dispatch, itemSearchText, filterQuery]);

    const getItemsData = async () => {

        if (itemSearchText) {
            await dispatch(getItems({ search: itemSearchText }));
        }

        else if (filterQuery) {
            await dispatch(getItems(filterQuery));
        }

        else {
            const response = await dispatch(getItems());
            if (response) {
                setPaymentOutDetail({
                    ...paymentOutDetail,
                    partiesCount: response.party_count,
                    toCollect: response.total_to_collect_balance,
                    toPay: response.total_to_pay_balance,
                });
            }
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    const navigate = useNavigate();

    const itemList = () => {
        navigate('/product/form');
    };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUnitModalVisible, setIsUnitModalVisible] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [newUnit, setNewUnit] = useState('');

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleUnitOk = () => {
        setIsUnitModalVisible(false);
    };

    const handleUnitCancel = () => {
        setIsUnitModalVisible(false);
    }

    const handleView = (record) => {
        navigate("/product/form", { state: { record } });
    };

    const columns = [
        { title: 'Item Name', dataIndex: 'item_name' },
        { title: 'Model No.', dataIndex: 'model_no' },
        { title: 'Category', dataIndex: 'category_name' },
        { title: 'Brand', dataIndex: 'brand' },
        { title: 'GST', dataIndex: 'gst_percentage' },
        { title: 'Qty', dataIndex: 'current_qty' },
        { title: 'Unit', dataIndex: 'unit_name' },
        { title: 'Rate', dataIndex: 'current_rate' },
    ];

    const tableColumns = columns.map((column) => ({
        title: column.title,
        dataIndex: column.dataIndex,
        key: column.dataIndex,
        sorter: ['item_name', 'brand', 'model_no'].includes(column.dataIndex)
    }));


    // Add an action column
    tableColumns.push({
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <span className="flex space-x-2">
                <Tooltip title="View" color="black">
                    <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
                        <EyeOutlined className="text-white p-0 m-0" />
                    </button>
                </Tooltip>
            </span>
        ),
    });

    return (
        <div>
            {isModalVisible &&
                <Modal
                    // width={'70vw'}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    title="Category"
                    footer={null}
                >
                    <CategoryContained />
                </Modal>
            }

            {isUnitModalVisible &&
                <Modal
                    // width={'70vw'}
                    visible={isUnitModalVisible}
                    onCancel={handleUnitCancel}
                    onOk={handleUnitOk}
                    title="Unit"
                    footer={null}
                >
                    <UnitContained />
                </Modal>
            }

            <AppListHeader
                headerContent='Item List'
                excel={true}
                print={true}
                printNavigation='/ListPrint'
                printData={{ tableColunm: columns, tableData: itemsResponse?.items?.results, name: 'Item List' }}
                download={false}
                cancel={true}
            />

            <div className="my-3 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                <Search
                    placeholder="Search by item name, brand, model no and hsn code"
                    allowClear
                    onSearch={(e) => { handleSearchText(e, setItemSearchText); }}
                    className="w-full md:w-1/2 lg:w-1/3"
                />
                <Button className="bg-[#a854f9] text-white w-full md:w-auto" onClick={() => setIsModalVisible(true)}>
                    <b>Add Category</b>
                </Button>
                <Button className="bg-violet-500 text-white w-full md:w-auto" onClick={() => setIsUnitModalVisible(true)}>
                    <b>Add Unit</b>
                </Button>
                <Button className="bg-violet-600 text-white w-full md:w-auto" onClick={itemList}>
                    <b>Create Item</b>
                </Button>
            </div>
            <ItemList
                columns={columns}
                tableColumns={tableColumns}
                items={itemsResponse?.items?.results}
                handleTableChange={handleTableChange}
            />
        </div>
    );
}
