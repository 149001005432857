import React, { useEffect, useState } from 'react'
import PartyList from './PartyList'
import { Button, Input, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getParties, getPartiesSuccess } from './actions';

const { Search } = Input;
const { Option } = Select;

const columns = [
  { title: 'Party Name', dataIndex: 'company_name', key: 'company_name' },
  { title: 'Mobile No', dataIndex: 'mobile_no', key: 'mobile_no' },
  { title: 'Party Type', dataIndex: 'party_type', key: 'party_type' },
  { title: ' State ', dataIndex: 'state', key: 'state' },
  { title: ' City', dataIndex: 'city', key: 'city' },
  { title: 'GST No', dataIndex: 'gst_no', key: 'gst_no' },
  { title: 'Opening Balance', dataIndex: 'opening_balance', key: 'opening_balance' },
  { title: 'Current Balance', dataIndex: 'current_balance', key: 'current_balance' },
];
export default function PartyDashboard() {

  const dispatch = useDispatch();

  const partyDatas = useSelector(state => state.parties.getParties.response)

  const [partySearchText, setPartySearchText] = useState('')   // state for set the searched value
  // const [filterQuery, setFilterQuery] = useState({
  //   company_name: '',
  //   party_type: '',
  //   gst_no: '',
  //   ordering: ''
  // })  
  // const [filterQuery, setFilterQuery] = useState({
  //   page: 1,
  //   page_size: 2
  // })                                                           // state for filter query

  const [filterQuery, setFilterQuery] = useState(null)

  const [partiesDetail, setPartyDetail] = useState({
    partiesCount: 0,
    toCollect: 0,
    toPay: 0
  })                                                            // state for set the amount and party count


  const [paginationDetail, setPaginationDetail] = useState({
    currentPage: 1,
    partyCount: partyDatas?.parties?.results?.count,
    pageSize: filterQuery?.page_size
  })

  const [filter, setFilter] = useState('all');


  useEffect(() => {
    /**
    * handle party state
    */
    // if (
    //   partyDatas?.party_count == partyDatas?.parties?.results?.length &&
    //   partyDatas?.parties?.results?.length > 0
    // ) {
    // }
    // else {
    //   alert("qqqqqqqqqqqqqqqqqqqqqqqqq")
    //   getPartiesData()
    // }
    getPartiesData()
    /**
     * end of handling party state
     */
  }, [dispatch, partySearchText, filterQuery]);


  const getPartiesData = async () => {

    if (partySearchText) {
      await dispatch(getParties({ search: partySearchText }));
    }
    else if (filterQuery) {
      const response = await dispatch(getParties(filterQuery));
      if (response) {
        setPartyDetail({
          ...partiesDetail,
          partiesCount: response.party_count,
          toCollect: response.total_to_collect_balance,
          toPay: response.total_to_pay_balance,
        });
      }
    }
    else {
      const response = await dispatch(getParties());
      if (response) {
        setPartyDetail({
          ...partiesDetail,
          partiesCount: response.party_count,
          toCollect: response.total_to_collect_balance,
          toPay: response.total_to_pay_balance,
        });
      }
    }
  };


  const navigate = useNavigate()

  // const handlePartySearchText = (value) => {
  //   // setPartySearchText(value)
  // };

  const handlePartySearch = (value) => {
    setPartySearchText(value)
  };

  const handlePrint = () => {
    navigate('/ListPrint', { state: { tableData: partyDatas?.parties?.results, tableColunm: columns, name: 'Party List' } });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setFilterQuery({
      ...filterQuery,
      ordering: sorter?.order == 'ascend' ?
        `${sorter?.field}`
        :
        sorter?.order == 'descend' ?
          `-${sorter?.field}`
          :
          null,
      // page: pagination?.current,
      // page_size: filterQuery?.page_size
    })
  }

  const handlePageChange = (pageNumber) => {
    setFilterQuery({
      ...filterQuery,
      page: pageNumber,
      // page_size: filterQuery?.page_size
    })

    // setPaginationDetail({
    //   ...paginationDetail,
    //   currentPage: pageNumber
    // })
  }

  const onShowSizeChange = (current, pageSize) => {
    setFilterQuery({
      ...filterQuery,
      page_size: pageSize
    })
    // alert(JSON.stringify(pageSize) + "kkkk")
    // setPaginationDetail({
    //   ...paginationDetail,
    //   pageSize: pageSize
    // })
  }

  const handlePartyFilter = (value) => {
    setFilter(value);
    let updatedFilterQuery = { ...filterQuery };

    if (value === 'To Pay') {
      updatedFilterQuery = { party_type: 'To Pay' };
    } else if (value === 'To Collect') {
      updatedFilterQuery = { party_type: 'To Collect' };
    } else {
      updatedFilterQuery = null;
    }

    setFilterQuery(updatedFilterQuery);
  };


  return (
    <div>
      <div className="">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-xl font-semibold">Parties</h2>
          {/* <div className="flex space-x-2">
            <Button className="bg-blue-500 text-white border-none py-1 px-3 h-8">
              Reports
            </Button>
            <Button className="border-blue-500 text-blue-500 border py-1 px-3 h-8">
              Settings
            </Button>
          </div> */}
        </div>
        <div>
          <div className="flex items-center space-x-2 mb-2">
            <div className="flex-1 p-2 border rounded-lg shadow-lg">
              <p className="text-gray-500">All Parties</p>
              {/* <h3 className="text-2xl font-bold">{partiesDetail?.partiesCount}</h3> */}
              <h3 className="text-2xl font-bold">{partyDatas?.party_count}</h3>
            </div>
            <div className="flex-1 p-2 border rounded-lg shadow-lg">
              <p className="text-green-500">To Collect</p>
              {/* <h3 className="text-2xl font-bold text-green-500">₹ {partiesDetail?.toCollect}</h3> */}
              <h3 className="text-2xl font-bold text-green-500">₹ {partyDatas?.total_to_collect_balance}</h3>
            </div>
            <div className="flex-1 p-2 border rounded-lg shadow-lg">
              <p className="text-red-500">To Pay</p>
              <h3 className="text-2xl font-bold text-red-500">₹ {partyDatas?.total_to_pay_balance}</h3>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <div className="relative flex-1">
              <Search
                placeholder="Search here"
                allowClear
                onSearch={handlePartySearch}
                className="w-full md:w-1/2 lg:w-1/3"
              />
              {/* <input
                    type="text"
                    placeholder="Search"
                    className="w-full p-1.5 border rounded-lg h-8"
                  />
                  <svg className="absolute top-1.5 left-2 w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M12.9 14.32A8 8 0 1114.32 12.9l5.388 5.388a1 1 0 01-1.415 1.415l-5.388-5.388zM10 16a6 6 0 100-12 6 6 0 000 12z" clipRule="evenodd" />
                  </svg> */}
            </div>
            <Button className="ml-auto bg-[#a854f9] text-white" onClick={handlePrint}>
              <b>Print</b>
            </Button>
            <Select
              className="p-1 w-44 rounded-lg h-10"
              value={filter}
              onChange={handlePartyFilter}
            >
              <Option value="all">Select Categories</Option>
              <Option value="To Pay">To Pay</Option>
              <Option value="To Collect">To Collect</Option>
            </Select>
            {/* <Button
              onClick={() => navigate('/party-form')}
              className="
                bg-violet-600 text-white border-none py-1 px-3 h-8
                "
            >
              <b>Create Party</b>
            </Button> */}
            <Button
              onClick={() => navigate('/party-form')}
              className="ml-2 bg-violet-600 text-white"
            >
              <b>Create Party</b>
            </Button>
          </div>

          <PartyList
            paginationDetail={paginationDetail}
            handlePageChange={handlePageChange}
            // pageSize={Math.ceil(partyDatas?.parties?.count / filterQuery?.page_size)}
            parties={partyDatas?.parties?.results}
            handleTableChange={handleTableChange}
            onShowSizeChange={onShowSizeChange}
          />
          {/* :
              "Error"
          } */}
        </div>
      </div>
    </div>
  )
}