import React from 'react';
import ExpensesTable from './ExpensesTable'; // Renamed from ExpensesTable
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

export default function Expenses({ expenseDatas, handleTableChange }) { // Renamed from  Expenses and prop to expenses

  /**
   * Define columns for the table with updated headers and accessors
   */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Expense Number', accessor: 'exp_no' }, // Updated the accessor from expense_no
    {Header: 'Payment Mode', accessor: 'payment_mode'},
    { Header: 'Total Amount', accessor: 'total_amount' },
  ];

  const navigate = useNavigate();

  // Function to handle navigation for viewing details
  const handleView = (record) => {
    navigate("/expenses-view", { state: { record } }); // Updated the route
  };


  // Convert columns to the format expected by Ant Design Table
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    sorter: ['total_amount', 'billed_to', 'exp_no', 'date'],
    ellipsis: true,
  }));

  // Add an action column for viewing and editing
  tableColumns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            <EyeOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
        {/* <Tooltip title="Edit" color="blue">
          <button className="p-1 mx-0.5 bg-blue-500 rounded">
            <EditOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
      </span>
    ),
  });


  return (
    <div>
      <ExpensesTable
        columns={columns}
        data={expenseDatas}
        tableColumns={tableColumns}
        handleTableChange={handleTableChange}
      /> {/* Updated the component and props */}
    </div>
  );
}
