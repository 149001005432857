import React, { useEffect, useState } from 'react';
import { Card, Button, Select, Input, Table, Tag, DatePicker } from 'antd';
import { CheckOutlined, WarningOutlined, ShoppingCartOutlined, UnorderedListOutlined, DisconnectOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchases } from './actions';
import PurchaseList from './PurchaseList';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';

const { Option } = Select;
const { Column } = Table;
const { Search } = Input;

const { RangePicker } = DatePicker;

export default function PurchaseDashboard() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const purchaseDatas = useSelector(state => state.purchase.getPurchases.response);
    const deletePurchaseResponse = useSelector(state => state.purchase.deletePurchase.response);

    const [purchaseSearchText, setPurchaseSearchText] = useState('')   // state for set the searched value

    const [filterQuery, setFilterQuery] = useState({ payment_status: 'unpaid_or_partial_paid'})                                                           // state for filter query
    const [purchasesDetail, setPurchasesDetail] = useState({
        totalPurchase: 0,
        totalPaidAmount: 0,
        totalUnPaidAmount: 0
    });


    useEffect(() => {
        getPurchasesData();
    }, [dispatch, purchaseSearchText, filterQuery, deletePurchaseResponse]);

    const getPurchasesData = async () => {

        if (purchaseSearchText) {
            await dispatch(getPurchases({ search: purchaseSearchText }));
        }

        else if (filterQuery) {
            const response = await dispatch(getPurchases(filterQuery));
            if (response) {
                setPurchasesDetail({
                    ...purchasesDetail,
                    totalPurchase: response.total_purchases,
                    totalPaidAmount: response.total_paid_amount,
                    totalUnPaidAmount: response.total_unpaid_amount,
                });
            }
        }

        else {
            const response = await dispatch(getPurchases());

            // if (response) {
            //     setPurchasesDetail({
            //         ...purchasesDetail,
            //         totalPurchase: response.total_purchases,
            //         totalPaidAmount: response.total_paid_amount,
            //         totalUnPaidAmount: response.total_unpaid_amount,
            //     });
            // }
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    const onCreatePurchaseInvoice = () => {
        navigate('/purchase/purchase-form');
    };

    const handlePrint = () => {
        navigate('/purchase-invoice-bill/print', { state: { purchaseDatas } });
    };

    const handlepurchaseSearch = (value) => {
        setPurchaseSearchText(value)
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming purchaseDatas is the list you want to export
        const wsData = purchaseDatas?.purchases?.results.map((e, index) => [
            index + 1,
            e.date, // Replace with correct date format if necessary
            e.purchase_inv_no,
            e.billed_to_company_name,
            e.total_amount,
            e.balance_amount,
            e.payment_status,

        ]);

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'S.No.',
                'Date',
                'Purchase Invoice Number',
                'Party Name',
                'Amount',
                'Balance Amount',
                'Status',
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchases');
        XLSX.writeFile(workbook, 'Purchases.xlsx');
    };

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setFilterQuery({
                ...filterQuery,
                start_date: dateStrings[0],
                end_date: dateStrings[1]
            })
        } else {
            console.log('Clear');
        }
    };



    return (
        <div className="">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold">Purchase Invoices</h1>
                <div className="flex items-center space-x-2">
                    <Button className="bg-primary text-primary-foreground" onClick={() => navigate('/reports/purchases-report')}>
                        Reports
                    </Button>
                    <Button className="bg-secondary text-secondary-foreground">
                        Settings
                    </Button>
                </div>
            </div>

            <div>
                <div className="grid grid-cols-3 gap-4 mb-4">
                    <Card className="border border-blue-500 bg-blue-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><UnorderedListOutlined className="mr-2" />Total Purchase</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {purchasesDetail?.totalPurchase}</p>}
                        />
                    </Card>
                    <Card className="border border-green-500 bg-green-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><CheckOutlined className="mr-2" />Paid</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {purchasesDetail?.totalPaidAmount}</p>}
                        />
                    </Card>
                    <Card className="border border-red-500 bg-red-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><DisconnectOutlined className="mr-2" />Unpaid</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {purchasesDetail?.totalUnPaidAmount}</p>}
                        />
                    </Card>
                </div>
                {/* <RangePicker presets={rangePresets} onChange={onRangeChange} /> */}
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                        <Search
                            placeholder="Search by Invoice no. and Company Name"
                            allowClear
                            onSearch={handlepurchaseSearch}
                            className="w-full"
                        />
                        {/* <Input
                            placeholder="Search Purchase Invoice"
                            onClick={handlePartySearch}
                            className="border border-border rounded-md"
                        /> */}
                        {/* <Select defaultValue="Last 365 Days" className="mr-2">
                            <Option value="7">Last 7 Days</Option>
                            <Option value="30">Last 30 Days</Option>
                            <Option value="365">Last 365 Days</Option>
                        </Select> */}
                    </div>
                    <Button className="ml-auto bg-[#a854f9] text-white" onClick={handlePrint}>
                        <b>Print</b>
                    </Button>
                    <Button onClick={handleExportToExcel}
                        className='ml-2 bg-white-100 text-blue-500 border-blue-500'
                    >
                        <b>Excel</b>
                        <DownloadOutlined />
                    </Button>
                    {/* <Button type="dashed" icon={<ShoppingCartOutlined />} className="ml-2">
                        Bulk download
                    </Button> */}
                    <Button className="ml-2 bg-violet-600 text-white" onClick={onCreatePurchaseInvoice}>
                        <b>Create Purchase Invoice</b>
                    </Button>
                </div>
                {/* <div className="overflow-x-auto">
                    <Table
                        dataSource={purchaseData}
                        pagination={false}
                        className="min-w-full bg-card rounded-md border border-border"
                    >
                        <Column
                            title="Date"
                            dataIndex="date"
                            key="date"
                            className="p-2 text-left"
                        />
                        <Column
                            title="Purchase Invoice Number"
                            dataIndex="invoiceNumber"
                            key="invoiceNumber"
                            className="p-2 text-left"
                        />
                        <Column
                            title="Party Name"
                            dataIndex="partyName"
                            key="partyName"
                            className="p-2 text-left"
                        />
                        <Column
                            title="Due In"
                            dataIndex="dueIn"
                            key="dueIn"
                            className="p-2 text-left"
                        />
                        <Column
                            title="Amount"
                            dataIndex="amount"
                            key="amount"
                            className="p-2 text-left"
                        />
                        <Column
                            title="Status"
                            dataIndex="status"
                            key="status"
                            className="p-2 text-left"
                            render={(status) => (
                                <div className="flex items-center space-x-2">
                                    <Tag color="red">{status}</Tag>
                                    <WarningOutlined style={{ color: 'red' }} />
                                </div>
                            )}
                        />
                    </Table>
                </div> */}
                <PurchaseList
                    purchase={purchaseDatas?.purchases?.results}
                    handleTableChange={handleTableChange}
                />
            </div>
        </div>
    );
}
