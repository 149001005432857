import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Row, Col, Card, Divider, Select, message } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getParties } from '../../party/actions'
import { saveDeliveryChallan } from './actions';
import { Link, useNavigate } from 'react-router-dom';
import { getItems } from '../../product/item/actions';
import secureLocalStorage from 'react-secure-storage';

export default function Deliveryform() {

    const dispatch = useDispatch()

    const partyResponse = useSelector(state => state.parties.getParties.response)
    const itemResponse = useSelector(state => state.items.getItems.response)

    const navigate = useNavigate()

    useEffect(() => {
        /**
         * handle party state
         */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results?.length &&
            partyResponse?.parties?.results?.length > 0
        ) {

        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */

        /**
        * handle item state
        */
        if (
            itemResponse?.items_count == itemResponse?.items?.results?.length &&
            itemResponse?.items?.results?.length > 0
        ) {
        }
        else {
            dispatch(getItems())
        }
        /**
         * end of handling item state
         */

    }, [dispatch])

    const [form] = Form.useForm();
    const [items, setItems] = useState([{ amount: 0 }]);
    const [freightAmount, setFreightAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [balanceAmount, setBalanceAmount] = useState(0);

    const initialValues = {
        purchase_order_no: '',
        billed_to: '',
        date: '',
        sub_total: 0.0,
        gst_amount: 0.0,
        shipping_charge: 0.0,
        total_amount: 0.0,
        description: "",
        delivery_challan_items: [{ amount: 0 }]
    };

    const handleErrors = (error) => {
        if (error.response && error.response.data) {
            // Assuming the backend returns an array of error messages
            const errorMessages = error.response.data.errors || [error.response.data.message];
            errorMessages.forEach(msg => message.error(msg));
        } else {
            message.error("An unexpected error occurred. Please try again.");
        }
    };


    const onFinish = async (values) => {
        window.scroll(0, 0);

        const delivery_challan_items = items.map(item => ({
            item: item.id,
            qty: parseFloat(item.qty),
            rate: parseFloat(item.rate).toFixed(2),
            discount: parseFloat(item.discount).toFixed(2),
            total: parseFloat(item.qty * item.rate).toFixed(2),
            gst_amount: parseFloat(((parseFloat(item.qty) * parseFloat(item.rate)) * parseFloat(item.gst)) / 100).toFixed(2),
            amount: parseFloat(item.amount).toFixed(2),
        }));

        values['delivery_challan_items'] = delivery_challan_items;

        try {
            const response = await dispatch(saveDeliveryChallan(values));
            if (response?.success) {
                navigate(-1);
            }
        } catch (error) {
            handleErrors(error);
        }
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleItemChange = (index, field, value) => {

        const newItems = [...items];
        newItems[index][field] = value;

        if (field == "item") {
            const itemObj = itemResponse?.items?.results?.find(dt => dt?.id == value)
            newItems[index].rate = itemObj?.current_rate
            newItems[index].gst = itemObj?.gst_percentage
            newItems[index].id = itemObj?.id
            newItems[index].hsn = itemObj?.hsn_code
        }

        newItems[index].amount = calculateAmount(newItems[index]); // Update amount when fields change

        setItems(newItems);
        form.setFieldsValue({ delivery_challan_items: newItems }); // Update form state
        updateTotalValues(newItems, freightAmount); // Update totals when an item changes
    };

    const calculateAmount = (item) => {
        const rate = parseFloat(item.rate || 0);
        const qty = parseFloat(item.qty || 0);
        const discount = parseFloat(item.discount || 0);
        const gst = parseFloat(item.gst || 0);
        const total = (rate * qty);
        const gstAmount = (total * gst) / 100;
        return Math.round((total + gstAmount - discount).toFixed(2));
    };

    const updateTotalValues = (updatedItems, freight) => {
        let subtotal = updatedItems.reduce((sum, item) => sum + parseFloat((item.qty * item.rate) || 0), 0);
        const gstTotal = updatedItems.reduce((sum, item) => sum + ((parseFloat(item.rate || 0) * parseFloat(item.qty || 0)) * (parseFloat(item.gst || 0) / 100)), 0);
        const totalDiscount = updatedItems.reduce((sum, item) => sum + parseFloat((item.discount) || 0), 0);
        // let subtotal = updatedItems.reduce((sum, item) => sum + ((parseFloat(item.price || 0) * parseFloat(item.qty || 0)), 0))
        // subtotal = subtotal - gstTotal
        const totalAmount = Math.round(parseFloat(subtotal)) + Math.round(gstTotal) - Math.round(totalDiscount) + parseFloat(Math.round(freight) || 0);

        form.setFieldsValue({
            sub_total: parseFloat(subtotal).toFixed(2),
            gst_amount: Math.round(gstTotal).toFixed(2),
            total_amount: Math.round(totalAmount).toFixed(2),
        });

        // updateBalanceAmount(totalAmount, amountPaid);

    };

    // const updateBalanceAmount = (totalAmount, amountPaid) => {
    //     const balance = totalAmount - amountPaid;
    //     setBalanceAmount(balance.toFixed(2));
    //     form.setFieldsValue({
    //         balance_amount: balance.toFixed(2),
    //     });
    // };

    const validateNumber = (maxLength) => (_, value) => {
        if (!value) {
            return Promise.resolve();
        }
        const regex = new RegExp(`^\\d{1,${maxLength}}$`);
        if (!regex.test(value)) {
            return Promise.reject(`Please enter a valid number with up to ${maxLength} digits.`);
        }
        return Promise.resolve();
    };

    useEffect(() => {
        updateTotalValues(items, freightAmount);
    }, [items, freightAmount]);

    useEffect(() => {
        form.setFieldsValue({ delivery_challan_items: items });
    }, [items, form]);

    // useEffect(() => {
    //     const totalAmount = parseFloat(form.getFieldValue('amount') || 0);
    //     // updateBalanceAmount(totalAmount, amountPaid);
    // }, [amountPaid]);

    return (
        <div>
            <Form
                form={form}
                name="delivery_challan_form"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="mx-auto bg-white rounded-md"
                initialValues={initialValues}
            >
                <div className="flex justify-between sticky top-[65px] mb-4 z-10 bg-white">
                    <h2 className="text-2xl font-semibold">Create Delivery Challan</h2>
                    <div className="flex space-x-2">
                        <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
                            <b>Save</b>
                        </Button>
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b>Cancel</b>
                        </Button>
                    </div>
                </div>
                <Card title="Party Name" size="small" className="w-full shadow-custom-light shadow-custom-dark"
                    style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }} >
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-1 ">
                        <Form.Item
                            label="Billed To"
                            name="billed_to"
                            rules={[{ required: true, message: 'Please select billing name!' }]}
                        >
                            <Select
                                placeholder='Select party'
                                showSearch
                                optionFilterProp='children'
                                dropdownRender={menu => (
                                    <>
                                        <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                            <Link
                                                className='flex ml-auto text-blue-500'
                                                to='/party-form'
                                            >
                                                New Party
                                            </Link>
                                        </div>
                                        <Divider style={{ margin: '0px 0' }} />
                                        {menu}
                                    </>
                                )}
                            >
                                {partyResponse?.parties?.results?.map((data, id) => (
                                    <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Purchase Inv No"
                            name="purchase_order_no"
                            rules={[{ required: true, message: 'Please input the Purchase Invoice Number!' }]}
                        >
                            <Input maxLength={10} placeholder="Purchase Inv No" />
                        </Form.Item>
                        <Form.Item
                            label="Purchase Inv Date"
                            name="date"
                            rules={[{ required: true, message: 'Please select the Purchase Invoice Date!' }]}
                        >
                            <Input type='date' />
                        </Form.Item>
                    </div>
                </Card>

                <Form.List name="delivery_challan_items">
                    {(fields, { add, remove }) => (
                        <div className='mt-4' style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
                            <table className="w-full mb-6 border-collapse">
                                <thead>
                                    <tr>
                                        <th className="p-2 border border-gray-300">NO</th>
                                        <th className="p-2 border border-gray-300">ITEMS/ SERVICES</th>
                                        <th className="p-2 border border-gray-300">HSN/ SAC</th>
                                        <th className="p-2 border border-gray-300">QTY</th>
                                        <th className="p-2 border border-gray-300">PRICE/ITEM (₹)</th>
                                        <th className="p-2 border border-gray-300">DISCOUNT</th>
                                        <th className="p-2 border border-gray-300">GST</th>
                                        <th className="p-2 border border-gray-300">AMOUNT (₹)</th>
                                        <th className="p-2 border border-gray-300">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                        <tr key={key}>
                                            <td className="p-2 border border-gray-300">{index + 1}</td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'item']}
                                                    fieldKey={[fieldKey, 'item']}
                                                    rules={[{ required: true, message: 'Missing item name' }]}
                                                    className="mb-0 w-[200px]"
                                                >
                                                    <Select
                                                        placeholder='Select item'
                                                        showSearch
                                                        optionFilterProp='children'
                                                        onChange={(value) => handleItemChange(index, 'item', value)}
                                                    >
                                                        {itemResponse?.items?.results?.map((data) => (
                                                            <Select.Option
                                                                key={data?.id}
                                                                value={data?.id}
                                                            >
                                                                {data?.item_name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'hsn']}
                                                    fieldKey={[fieldKey, 'hsn']}
                                                    rules={[{ required: true, message: 'Missing HSN code' }]}
                                                    className="mb-0"
                                                >
                                                    <Input
                                                        onChange={(e) => handleItemChange(index, 'hsn', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'qty']}
                                                    fieldKey={[fieldKey, 'qty']}
                                                    rules={[
                                                        { required: true, message: 'Missing quantity' },
                                                        { validator: validateNumber(3) }
                                                    ]}
                                                    className="mb-0"
                                                >
                                                    <Input
                                                        onChange={(e) => handleItemChange(index, 'qty', Number(e.target.value))}
                                                        maxLength={3}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'rate']}
                                                    fieldKey={[fieldKey, 'rate']}
                                                    className="mb-0"
                                                // rules={[
                                                //     {
                                                //         validator: (_, value) =>
                                                //             value >= 0
                                                //                 ? Promise.reject('rate should be greater than 0')
                                                //                 : Promise.resolve(),
                                                //     },
                                                // ]}
                                                >
                                                    <Input
                                                        // readOnly
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Allow only numbers and a single decimal point
                                                            const validValue = value.replace(/[^0-9.]/g, '');

                                                            // Prevent more than one decimal point
                                                            if ((validValue.match(/\./g) || []).length <= 1) {
                                                                handleItemChange(index, 'rate', validValue);
                                                            }
                                                        }}
                                                        // onChange={(e) => handleItemChange(index, 'rate', e.target.value)}
                                                        maxLength={10}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'discount']}
                                                    fieldKey={[fieldKey, 'discount']}
                                                    rules={[
                                                        { validator: validateNumber(6) }
                                                    ]}
                                                    className="mb-0"
                                                >
                                                    <Input
                                                        onChange={(e) => handleItemChange(index, 'discount', e.target.value)}
                                                        maxLength={6}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'gst']}
                                                    fieldKey={[fieldKey, 'gst']}
                                                    rules={[{ required: true, message: 'Missing GST' }]}
                                                    className="mb-0"
                                                >
                                                    <Input
                                                        onChange={(e) => handleItemChange(index, 'gst', e.target.value)} readOnly
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'amount']}
                                                    fieldKey={[fieldKey, 'amount']}
                                                    className="mb-0"
                                                >
                                                    <Input
                                                        readOnly
                                                        onChange={(e) => handleItemChange(index, 'amount', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Button
                                                    type="danger"
                                                    onClick={() => {
                                                        const newItems = [...items];
                                                        newItems.splice(index, 1);
                                                        setItems(newItems);
                                                        form.setFieldsValue({ items: newItems });
                                                        updateTotalValues(newItems, freightAmount);
                                                    }}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="9" className="p-2 border border-gray-300">
                                            <Button type="dashed"
                                                onClick={() => {
                                                    add();
                                                    setItems([...items, { amount: 0 }]); // Add new item to state
                                                }} block>
                                                Add Item
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </Form.List>

                <div className="mt-4 flex w-full md:flex-row flex-col-reverse  justify-between">
                    <div
                        className='md:w-[45%] w-[100%]'

                    >
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                { required: true, message: 'Enter the description' }
                            ]}
                        >
                            <Input.TextArea
                                placeholder="Enter Description"
                                className="w-full h-10"
                                style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
                                autoSize={{ minRows: 5, maxRows: 8 }}
                            />
                        </Form.Item>
                    </div>
                    <Card
                        // title="Amount"
                        className="text-left md:w-[45%] w-[100%] shadow-lg p-0 bg-[rgba(0,0,0,0.02)]"
                        style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
                    >
                        <Form.Item
                            rules={[{ required: true, message: 'required' }]}
                            name="sub_total"
                            className='m-0 p-0'
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Sub Total</div>
                                <div>{form.getFieldValue('sub_total') ? form.getFieldValue('sub_total') : '0.00'}</div>
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="shipping_charge"
                            rules={[{ required: true, message: 'Shipping Charges is required' }]}
                            initialValue={0}
                            className='m-0 p-0'
                        >
                            <div className='flex justify-between'>
                                <div>Shipping Charges</div>
                                <input
                                    type="text"
                                    onChange={(value) => {
                                        setFreightAmount(value.target.value);
                                        updateTotalValues(items, value.target.value);
                                    }}
                                    maxLength={7}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="gst_amount"
                            className='m-0 p-0'
                        >
                            <div className='flex justify-between'>
                                <div>GST Amount</div>
                                <div>{form.getFieldValue('gst_amount') ? form.getFieldValue('gst_amount') : '0.00'}</div>
                            </div>
                        </Form.Item>
                        <Divider />
                        <Form.Item
                            name="total_amount"
                            className='m-0 p-0'
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Amount</div>
                                <div>{form.getFieldValue('total_amount') ? form.getFieldValue('total_amount') : '0.00'}</div>
                            </div>
                        </Form.Item>
                        <Divider />
                    </Card>
                </div>
            </Form>
        </div>
    );
}
