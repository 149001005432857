import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp, verifyOtp } from './actions';

export default function EmailVarification() {

    /**
     * To Handle the data and actions from redux 
     */
    const dispatch = useDispatch();
    // const loading = useSelector(state => state.postData.loading);
    // const error = useSelector(state => state.postData.error);

    const sendOtpLoading = useSelector(state => state.emailVarifies.sendOtp.loading);
    const verifyOtpLoading = useSelector(state => state.emailVarifies.verifyOtp.loading);
    const sendOtpError = useSelector(state => state.emailVarifies.error);
    const sendOtpResponse = useSelector(state => state.emailVarifies.response);


    /**
     * Object creation for the useForm hook
     */
    const [form] = Form.useForm()

    const [showOtpBox, setShowOtpBox] = useState(false);

    const navigate = useNavigate()

    const handleSendOtp = async (values) => {

        const response = showOtpBox ? await dispatch(verifyOtp(values)) : await dispatch(sendOtp(values))

        if (response?.success == "Please check your email for OTP.") {
            message.success(response?.success)
            setShowOtpBox(true)
        } else if (response?.info == 'Email is already registered.') {
            message.error(response?.info)
        }
        else if (response?.expired) {
            message.error(response?.expired)
        }
        else if (response?.info == 'Email already verified.') {
            message.error(response?.info)
        } else if (response?.error) {
            message.error(response?.error)
        }

        // setShowOtpBox(true)
        if (response?.success === "Email verified successfully.") {
            navigate('/register', { state: { email: values.email } })
        }
    }

    useEffect(()=>{

    },[sendOtpLoading, verifyOtpLoading])


    return (
        <div className='w-[100%]'>
            <div
                className='
                float-right text-[14px]
                '
            >Already Have an Account? <Link className='text-[blue]' to="/login">Login</Link>
            </div>
            <br />
            <br />

            <div
                className='
                text-[18px] font-bold text-[#000080]
                '
            >
                Verify Your Email
            </div>
            <div
                className='
                text-[14px]
                '
            >
                Register to Continue
            </div>
            <br />

            <Form
                form={form}
                onFinish={handleSendOtp}
                layout='vertical'
            >
                <Form.Item
                    name="email"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Email"
                            },
                            {
                                type: 'email',
                                message: "Please Enter Valid Email"
                            }
                        ]
                    }
                >
                    <Input
                        placeholder='Enter Email ID'
                        maxLength={50}
                        disabled={showOtpBox ? true : false}
                    />
                </Form.Item>
                {showOtpBox &&
                    <Form.Item
                        name="otp"
                        rules={
                            [
                                {
                                    required: true,
                                    message: "Please Enter OTP"
                                }
                            ]
                        }
                    >
                        <Input.Password
                            placeholder='Enter OTP'
                            maxLength={50}
                        />
                    </Form.Item>
                }
                <Button
                    className='
                    rounded-2xl shadow-lg bg-[#000080] text-[white] px-10 h-[35px]
                    '
                    htmlType='submit'
                    disabled={sendOtpLoading || verifyOtpLoading}
                >
                    <b>{sendOtpLoading || verifyOtpLoading ? "Loading..." : showOtpBox ? "Verify OTP" : "Send OTP"}</b>
                </Button>
            </Form>
        </div>
    )
}

