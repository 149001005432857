
import React, { useEffect } from 'react'
import PaymentOutDashboardTable from './PaymentOutDashboardTable';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOrders ,deletePaymentOut} from './actions';
import { useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Tooltip, message } from 'antd';
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';



export default function QuotationList({ paymentOutDatas, handleTableChange }) {

const dispatch = useDispatch();
  // 
  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Company name', accessor: 'party_company_name' },
    { Header: 'Description', accessor: 'remark' },
    { Header: 'Payment Mode', accessor: 'payment_mode' },
    { Header: 'Amount', accessor: 'amount' }
  ];

  const navigate = useNavigate();

  // Function to handle navigation
  const handleView = (record) => {
    // navigate("/payment-out-invoice-bill", { state:  {record}  });
  };

  // Convert columns to the format expected by Ant Design Table
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    sorter: ['date', 'amount', 'billed_to'].includes(column.accessor),
    ellipsis: true,
  }));

  /**
 * to delete the 
 */
  const handleDelete = async (data) => {
    if (data?.id) {
      const response = await dispatch(deletePaymentOut(data?.id))
    } else {
      message.error("Please Select Data")
    }
  }
  // Add an action column
  tableColumns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        {/* <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            <EyeOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
        {/* <Tooltip title="Edit" color="blue">
          <button className="p-1 mx-0.5 bg-blue-500 rounded">
            <EditOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
        {/* <Tooltip title="Delete" color="red">
          <button className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
         <Popconfirm
          placement="topRight"
          title='object delete'
          description='Are you sure want to delete'
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button disabled={record?.payment_status === 'paid' ? true : false} className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-black p-0 m-0" />
          </Button>
        </Popconfirm>
      </span>
    ),
  });


  return (
    <div>
      <PaymentOutDashboardTable
        columns={columns}
        data={paymentOutDatas}
        tableColumns={tableColumns}
        handleTableChange={handleTableChange}
      />
    </div>
  )
}



