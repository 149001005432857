import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PrinterOutlined, DownloadOutlined, FileExcelOutlined, CloseOutlined } from '@ant-design/icons';

export default function AppListHeader({
    headerContent,
    creationLable,
    handleCreateNew,
    excel,
    handleExcelExport,
    print,
    download,
    cancel,
    printNavigation,
    printData
}) {

    const navigate = useNavigate()

    return (
        <div>
            <div className="flex sm:flex-row flex-col justify-between mb-1 bg-[#f5f5ef] p-2 border-b-2">
                <h2 className="text-2xl font-semibold w-fit">{headerContent}</h2>
                <div className="flex space-x-2">
                    {handleCreateNew &&
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={handleCreateNew}
                        >
                            <b>{creationLable}</b>
                        </Button>
                    }
                    {excel &&
                        <Button
                            htmlType="button"
                            className="shadow-inner bg-[green] text-white"
                            onClick={handleExcelExport}
                        >
                            <b><FileExcelOutlined /></b>
                        </Button>
                    }
                    {print &&
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                        onClick={() => navigate(printNavigation,{state:printData})}
                        >
                            <b><PrinterOutlined /></b>
                        </Button>
                    }
                    {download &&
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                        // onClick={() => navigate(-1)}
                        >
                            <b><DownloadOutlined /></b>
                        </Button>
                    }
                    {cancel &&
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b><CloseOutlined /></b>
                        </Button>
                    }
                </div>
            </div>
        </div>
    )
}
