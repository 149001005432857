import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, message, Divider } from 'antd';
import PaymentInTable from './PaymentInTable';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingSales, savePaymentIn } from './actions';
import { getParties } from '../../party/actions';
import { Link, useNavigate } from 'react-router-dom';
// import {savePaymentIn} from '../../../api/Services'


const CreatePaymentIn = () => {
    const [form] = Form.useForm();
    const [loadings, setLoadings] = useState([]);
    const [invoiceSettled, setInvoiceSettled] = useState(0);
    const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
    const [pendingSales, setPendingSales] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [totalRemainingAmount, setTotalRemainingAmount] = useState(0);

    const partyList = useSelector(state => state.parties.getParties.response);
    const partyResponse = useSelector(state => state.parties.getParties.response);
    const dispatch = useDispatch();

    useEffect(() => {
        /**
        * handle party state
        */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results?.length &&
            partyResponse?.parties?.results?.length > 0
        ) {
        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */
    }, [dispatch]);

    const navigate = useNavigate()

    const handleInvoiceSettledChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setInvoiceSettled(value);
        setPaymentAmount(value);
        setTotalRemainingAmount(value);
    };

    const validateInteger = (_, value) => {
        if (!value) {
            return Promise.resolve();
        }
        if (!Number.isInteger(Number(value))) {
            return Promise.reject(new Error('Please enter a valid integer'));
        }
        return Promise.resolve();
    };

    const onFinish = (values) => {
        const filteredInvoices = invoices
            .filter(invoice => invoice.checked)
            .map(invoice => ({
                sale: invoice.id,
                invoice_amount: invoice.total_amount,
                settled_amount: invoice.settled_amount,
            }));

        values['payment_ins'] = filteredInvoices


        const response = dispatch(savePaymentIn(values))
        if (response?.success) {
            navigate(-1)
        }

        // Add your savePaymentIn dispatch call here with the filteredInvoices data
        // dispatch(savePaymentIn({
        //     ...values,
        //     payment_ins: filteredInvoices
        // })).then(() => {
        //     message.success('Payment saved successfully');
        // }).catch(() => {
        //     message.error('Error saving payment');
        // });
    };

    const handleGetSales = async (id) => {
        let data = {
            billed_to__id: id,
            payment_status: "unpaid_or_partial_paid"
        };
        const response = await dispatch(getPendingSales(data));
        if (response.sales) {
            setPendingSales(response.sales);
            const transformedInvoices = response?.sales?.results?.map((invoice) => ({
                id: invoice.id,
                date: invoice.date,
                invoice_no: invoice.sale_inv_no,
                total_amount: invoice.total_amount,
                balance_amount: invoice.balance_amount,
                settled_amount: 0,
                checked: false
            }));

            setInvoices(transformedInvoices)

        }
    };

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    };

    return (
        <div className="">
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{ invoice_settled: invoiceSettled }}
                {...formItemLayout}
            >
                <div className="flex justify-between sticky top-[65px] mb-4 z-10 bg-white">
                    <h2 className="text-2xl font-semibold">New Payment In</h2>
                    <div className="flex space-x-2">
                        <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
                            <b>Save</b>
                        </Button>
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b>Cancel</b>
                        </Button>
                    </div>
                </div>
                <div className="flex justify-evenly gap-1 mt-2">
                    <Card title="Party Name" size="small" className="w-1/2"
                        style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                            <Form.Item
                                name="party"
                                label="Party Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Party"
                                    }
                                ]}
                                className='mb-2 p-0'
                            >
                                <Select
                                    onChange={handleGetSales}
                                    className="shadow-inner"
                                    placeholder='Select Party'
                                    showSearch
                                    optionFilterProp='children'
                                    dropdownRender={menu => (
                                        <>
                                            <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                                <Link
                                                    className='flex ml-auto text-blue-500'
                                                    to='/party-form'
                                                >
                                                    New Party
                                                </Link>
                                            </div>
                                            <Divider style={{ margin: '0px 0' }} />
                                            {menu}
                                        </>
                                    )}
                                >
                                    {partyList?.parties?.results?.map((party, id) => (
                                        <Select.Option
                                            key={id}
                                            value={party.id}
                                        >
                                            {party.company_name}, {party.current_balance}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="amount"
                                label="Amount"
                                rules={[
                                    { required: true, message: 'Please enter an invoice settled amount' },
                                    { validator: validateInteger },
                                ]}
                                className='mb-2 p-0'
                            >
                                <Input
                                    placeholder='Enter Payment Amount'
                                    className="shadow-inner"
                                    value={invoiceSettled}
                                    onChange={handleInvoiceSettledChange}
                                    addonAfter={<span>Remaining: ₹{totalInvoiceAmount}</span>}
                                    maxLength={10}
                                />
                            </Form.Item>
                        </div>
                    </Card>

                    <Card title="Payment Details" size="small" className="w-1/2"
                        style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>

                        <div className="grid grid-cols-1 md:grid-cols-1 gap-1">
                            <Form.Item
                                name="date"
                                label="Payment Date"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Date"
                                    }
                                ]}
                                className='mb-2 p-0'
                            >
                                <Input className="h-8 shadow-inner" type="date" />
                            </Form.Item>

                            <Form.Item
                                name="payment_mode"
                                label="Payment Mode"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Payment Mode"
                                    }
                                ]}
                                className='mb-2 p-0'
                            >
                                <Select placeholder="Select payment mode">
                                    <Select.Option value="BANK">BANK</Select.Option>
                                    <Select.Option value="CASH">CASH</Select.Option>
                                    <Select.Option value="CARD">CARD</Select.Option>
                                    <Select.Option value="UPI">UPI</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="remark"
                                label="Description"
                                rules={[
                                    {
                                        required: false,
                                        message: "Please Enter Description"
                                    }
                                ]}
                                className='mb-2 p-0'
                            >
                                <Input.TextArea className="h-8 shadow-inner" type='text' />
                            </Form.Item>
                        </div>
                    </Card>
                </div>
            </Form>
            {invoices.length > 0 && (
                <PaymentInTable
                    invoices={invoices}
                    setInvoices={setInvoices}
                    paymentAmount={paymentAmount}
                    setPaymentAmount={setPaymentAmount}
                    totalRemainingAmount={totalRemainingAmount}
                    setTotalRemainingAmount={setTotalRemainingAmount}
                />
            )}
        </div>
    );
}

export default CreatePaymentIn;
