import React, { useEffect } from 'react'
import SaleTable from './Saletable';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSale, getParties } from './actions';
import { useNavigate } from 'react-router-dom';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import SaleInvoice from '../SaleInvoiceBill'



export default function SaleList({ sale, handleTableChange }) {

  const dispatch = useDispatch()

  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Party Name', accessor: 'billed_to_company_name' },
    { Header: 'sale Invoice Number', accessor: 'sale_inv_no' },
    { Header: 'original Invoice Number', accessor: 'original_inv_no' },
    { Header: 'Amount', accessor: 'total_amount' },
    { Header: 'Balance Amount', accessor: 'balance_amount' },
    { Header: 'Status', accessor: 'payment_status' },
  ];


  const navigate = useNavigate();

  // Function to handle navigation
  const handleView = (record) => {
    navigate("/invoice2/print-dashboard", { state: { record: record } });
  };

  const handlePrint = (record) => {
    window.print(SaleInvoice);

  };

  // Convert columns to the format expected by Ant Design Table
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    sorter: ["total_amount", 'sale_inv_no', 'balance_amount', 'date'].includes(column.accessor),
    ellipsis: true,
  }));

/**
 * to delete the 
 */
  const handleDelete = async (data) => {
    if (data?.id) {
      const response = await dispatch(deleteSale(data?.id))
    } else {
      message.error("Please Select Data")
    }
  }


  // Add an action column
  
  tableColumns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            {/* <EyeInvisibleOutlined className="text-white p-0 m-0" /> */}
            <EyeOutlined className="text-white p-0 m-0"/>
          </button>
        </Tooltip>
        {/* <Tooltip title="Delete" color="red">
          <button className="p-1 mx-0.5 bg-red-500 rounded" >
            <DeleteOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
        {/* <Tooltip title="print" color="blue">
          <button className="p-1 mx-0.5 bg-blue-500 rounded" onClick={() => handlePrint(record)}>
            <p className='bg-blue-500 text-white'>Print</p>
          </button>
        </Tooltip> */}
        <Popconfirm
          placement="topRight"
          title='object delete'
          description='Are you sure want to delete'
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button disabled={record?.payment_status === 'paid' ? true : false} className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-black p-0 m-0" />
          </Button>
        </Popconfirm>
      </span>
    ),
  });

  
  return (
    <div>
      <SaleTable
        columns={columns}
        data={sale}
        tableColumns={tableColumns}
        handleTableChange={handleTableChange}
      />
    </div>
  )
}
