import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, DatePicker } from 'antd';
import ExpenseList from './ExpensesList';
import * as XLSX from 'xlsx';
import ExpenseCategoryContained from '../categary/ExpenseCategoryContained';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenses } from './actions'; // Ensure correct import
import AppListHeader from '../../header/AppListHeader';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function ExpenseDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const expenseDatas = useSelector((state) => state.expenses.getExpenses?.response); // Handle possible undefined cases

  const [expenseSearchText, setExpenseSearchText] = useState('');
  const [filterQuery, setFilterQuery] = useState({}); // Initialize filterQuery properly

  // Fetch expenses data based on search text or filter query
  const fetchExpenses = async () => {
    try {
      if (expenseSearchText) {
        await dispatch(getExpenses({ search: expenseSearchText }));
      } else {
        await dispatch(getExpenses(filterQuery)); // Dispatch based on filter query
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [expenseSearchText, filterQuery]); // Fetch data when these change

  const handleTableChange = (pagination, filters, sorter) => {
    setFilterQuery((prevFilterQuery) => ({
      ...prevFilterQuery,
      ordering:
        sorter?.order === 'ascend'
          ? sorter?.field
          : sorter?.order === 'descend'
          ? `-${sorter?.field}`
          : null,
    }));
  };

  const handleSearch = (value) => {
    setExpenseSearchText(value);
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setFilterQuery({
        ...filterQuery,
        start_date: dateStrings[0],
        end_date: dateStrings[1],
      });
    } else {
      setFilterQuery({
        ...filterQuery,
        start_date: null,
        end_date: null,
      });
    }
  };

  const handlePrint = () => {
    navigate('/purchase-invoice-bill/print', { state: expenseDatas?.expenses });
  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const wsData = expenseDatas?.expenses?.map((e, index) => [
      index + 1,
      e.date,
      e.exp_no,
      e.payment_mode,
      e.amount,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Date', 'Expense Number', 'Payment Mode', 'Amount'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Expenses');
    XLSX.writeFile(workbook, 'Expenses.xlsx');
  };

  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);

  const onCreateExpenseForm = () => {
    navigate('/expense/expense-form');
  };

  /**
     * To create for header and access key
     */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Expense No', accessor: 'exp_no' },
    { Header: 'Payment Mode', accessor: 'payment_mode' },
    { Header: 'Amount', accessor: 'total_amount' },
    { Header: 'Description', accessor: 'description' },
];

  var tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    // sorter: ["total_amount", 'balance_amount', 'date'].includes(column.accessor),
    ellipsis: true,
}));

  return (
    <div className="p-1">
      {/* Header component with Excel and Print functionalities */}
      <AppListHeader
        headerContent="Expense List"
        excel={true}
        print={true}
        handleExcelExport={handleExportToExcel}
        printNavigation='/ListPrint'
        printData={{ tableColunm: tableColumns, tableData: expenseDatas?.expenses, name: ' Expense List' }}
        download={false}
        cancel={true}
      />

      <div className="flex items-center space-x-4">
        {/* Search input */}
        <Search
          placeholder="Search Expenses"
          allowClear
          onSearch={handleSearch}
          style={{ width: 400 }}
        />

        {/* Date range picker */}
        <RangePicker presets={rangePresets} onChange={onRangeChange} />

        {/* Create buttons */}
        <Button className="bg-[#a854f9] text-white" onClick={() => setIsCategoryModalVisible(true)}>
          <b>Create Ex.Category</b>
        </Button>
        <Button className="ml-2 bg-violet-600 text-white" onClick={onCreateExpenseForm}>
          <b>Create Expense</b>
        </Button>
      </div>

      {/* Expense list table */}
      <ExpenseList expenseDatas={expenseDatas?.expenses} handleTableChange={handleTableChange} />
      {/* Category Modal */}
      <Modal
        title="Create Expense Category"
        visible={isCategoryModalVisible}
        onCancel={() => setIsCategoryModalVisible(false)}
        footer={null}
      >
        <ExpenseCategoryContained />
      </Modal>
    </div>
  );
}