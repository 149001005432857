import React, { useState } from 'react';
import './Contact.scss'
import { message } from 'antd';


export default function Contact() {

    const primaryColor = "green"

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'name':
                if (!value) error = 'Name is required.';
                break;
            case 'phone':
                const phonePattern = /^[0-9]{10}$/;
                if (!value) {
                    error = 'Phone number is required.';
                } else if (!phonePattern.test(value)) {
                    error = 'Phone number must be 10 digits.';
                }
                break;
            case 'email':
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!value) {
                    error = 'Email is required.';
                } else if (!emailPattern.test(value)) {
                    error = 'Email is invalid.';
                }
                break;
            case 'message':
                if (!value) error = 'Message is required.';
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        if (errors[name]) validateField(name, value);
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = Object.keys(formData).every(key => {
            validateField(key, formData[key]);
            return !errors[key];
        });

        // if (isValid) {
        //     massage(`Form Data:\nName: ${formData.name}\nPhone: ${formData.phone}\nEmail: ${formData.email}\nMessage: ${formData.message}`);
        // } else {
        //     massage('Please fix the errors in the form.');
        // }
        if(isValid){
            message.success('Details have been submitted');
        }
        else {
            message.error('Please fill valid details in the form.');
        }
    };

    return (
        <div id='contact'>
            <div
                className="
                 m-auto md:m-8 m-4
                "
            >
                <h2
                    className="
                    text-3xl font-semibold text-center mb-6 drop-shadow
                    "
                >
                    Contact
                </h2>

                <div
                    className='
                    border-solid border-2 border-[red] w-[20%] m-auto my-5
                    '
                />

                <form
                    onSubmit={handleSubmit}
                    className="
                    max-w-4xl mx-auto p-6 rounded-lg shadow-lg space-y-4
                    "
                >
                    <div
                        className="
                        grid grid-cols-1 md:grid-cols-3 gap-4
                        "
                    >
                        <div>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="
                                border border-gray-300 p-3 rounded-lg focus:outline-none
                                focus:border-blue-500 w-full
                                "
                            />
                            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                        </div>
                        <div>
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="border border-gray-300 p-3 rounded-lg focus:outline-none
                                focus:border-blue-500 w-full
                                "
                            />
                            {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
                        </div>
                        <div>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email address"
                                value={formData.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="
                                border border-gray-300 p-3 rounded-lg focus:outline-none
                                focus:border-blue-500 w-full
                                "
                            />
                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                        </div>
                    </div>
                    <div>
                        <textarea
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="border border-gray-300 p-3 rounded-lg w-full h-32
                            focus:outline-none focus:border-blue-500
                            "
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="
                            bg-violet-950 text-white px-6 py-2 rounded hover:bg-violet-900
                            font-semibold contact
                            "
                            style={{ '--primary-color': primaryColor }}
                        >
                            Contact Us
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
