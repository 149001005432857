import { message } from 'antd'
import API from './API'
import secureLocalStorage from 'react-secure-storage';

const publicTenant = "yashvibilling"


/**
 * send and save otp of the client detail
 */
export const sendOtpAPI = async (formData) => {
    const response = await API.post(`adminApp/api/send-otp/`, formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }).catch(
            err => message.error('otp failed.')
        )
    return response ? response.data : {}
}
/**
 * End of sending otp
 */

export const sendResetPasswordLinkAPI = async (data) => {
    const response = await API.post(`adminApp/api/request-password-reset/`, data,
        { headers: { 'Content-Type': 'multipart/form-data' } }).catch(
            err => message.error('Failed to send reset password link.')
        )
    return response ? response.data : {}
};

export const resetPasswordAPI = async (data, token) => {
    try {
        const response = await API.post(`adminApp/api/reset-password/${token}/`, data);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};

/**
 * varify the otp of the client
 */
export const varifyOtpAPI = async (formData) => {
    try {
        const response = await API.post(`adminApp/api/verify-otp/`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response ? response.data : {};
    } catch (error) {
        return { error: error.response ? error.response.data : 'An unknown error occurred' };
    }
}
/**
 * End of varifying otp
 */


/**
 * save the client detail
 */
export const saveClientAPI = async (formData) => {
    const response = await API.post(`adminApp/api/register/`, formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }).catch(
            err => message.error('client registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving client detail
 */


/**
 * To authencate the client or login api
 */
export const loginAPI = async (formData) => {
    const response = await API.post(`adminApp/api/login/`, formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }).catch(
            err => message.error('login failed.')
        )
    return response ? response.data : {}
}
/**
 * End of login client
 */


/**
 * To save the party detail
 */
export const savePartyAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant");
    const token = secureLocalStorage.getItem("access");
    try {
        const response = await API.post(`api/v1/${tenantName}/clientApp/parties/`, formData,
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });
        return response ? response.data : {};
    } catch (error) {
        return { error: error.response ? error.response.data : 'An unknown error occurred' };
    }
};
/**
 * End of saving party
 */


/**
 * To update the party detail
 */
export const updatePartyAPI = async (id, data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.put(`api/v1/${tenantName}/clientApp/parties/${id}/`, data,
        { headers: { 'Content-Type': 'application/json' ,'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('party updating failed.')
        )
    return response ? response.data : {}
}
/**
 * End of updating party
 */

/**
 * Update client API function
 */

export const updateClientAPI = async (clientId, data) => {
    const token = secureLocalStorage.getItem("access")
    try {
      const response = await API.put(`adminApp/api/clients/${clientId}/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Include any necessary authentication headers, e.g.:
          'Authorization': `Bearer ${token}`
        }
      });
      return response ? response.data : {}
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return { error: error.response.data };
      } else if (error.request) {
        // The request was made but no response was received
        return { error: 'No response received from server' };
      } else {
        // Something happened in setting up the request that triggered an Error
        return { error: 'Error setting up the request' };
      }
    }
  };

/**
 * To get the parties detail
 */
export const getPartiesAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/clientApp/parties/`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            // headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('Something went wrong')
        )
    return response ? response.data : {}
}
/**
 * End of getting parties
 */


/**
 * To get the paties
 */
export const getPartyAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenantName")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/parties/`,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }).catch(
            err => message.error('login failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting parties
 */


/**
 * To save the purchase detail
 */
export const savePurchaseAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    // const response = await API.post(`api/v1/${tenantName}/purchaseApp/purchases/`, formData,
    //     { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }).catch(
    //         err => message.error('purchase detail failed.')
    //     )
    // return response ? response.data : {}
    try {
        const response = await API.post(`api/v1/${tenantName}/purchaseApp/purchases/`, formData,
            {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            });
        return response ? response.data : {};
    } catch (error) {
        return { error: error.response ? error.response.data : 'An unknown error occurred' };
    }
}
/**
 * End of saving purchase
 */






/**
 * To save the payment in detail
 */
export const savePaymentInAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/saleApp/payment-ins/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('paiment in detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving payment in
 */
/**
 * To get paymentin list
 */
export const getPaymentInAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/saleApp/payment-ins/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('purchase detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting purchase list
 */

/**
 * To get payemntOut list
 */
export const getPaymentOutAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/purchaseApp/payment-outs/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('purchase detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting payemntOut list
 */

/**
 * To save the payment Out detail
 */
export const savePaymentOutAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/purchaseApp/payment-outs/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('paiment in detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving payment in
 */


/**
 * To get purchase list
 */
export const getPurchasesAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/purchaseApp/purchases/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('purchase detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting purchase list
 */


/**
 * To delete purchase list
 */
export const deletePurchasesAPI = async (id) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.delete(`api/v1/${tenantName}/purchaseApp/purchases/${id}/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        }).catch(
            err => message.error('purchase detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting purchase list
 */


/**
 * To delete sale
 */
export const deleteSalesAPI = async (id) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.delete(`api/v1/${tenantName}/saleApp/sales/${id}/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        }).catch(
            err => message.error('sale detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting purchase list
 */


/**
 * To sale the purchase detail
 */
export const saveSaleAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant");
    const token = secureLocalStorage.getItem("access");

    // const response = await API.post(
    //     `api/v1/${tenantName}/saleApp/sales/`,
    //     formData,
    //     {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}` 
    //         }
    //     }
    // ).catch(err => {
    //     message.error('Sale detail failed.');
    // });

    // return response ? response.data : {};
    try {
        const response = await API.post(`api/v1/${tenantName}/saleApp/sales/`, formData,
            {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            });
        return response ? response.data : {};
    } catch (error) {
        return { error: error.response ? error.response.data : 'An unknown error occurred' };
    }
};


/**
 * End of saving sale
 */


/**
 * To get sale list
 */
export const getSalesAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant");
    const token = secureLocalStorage.getItem("access");

    const response = await API.get(
        `api/v1/${tenantName}/saleApp/sales/`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            },
            params: data
        }
    ).catch(err => {
        message.error('Sales detail failed.');
    });

    return response ? response.data : {};
};

/**
 * End of getting sales list
 */



/**
 * To sale the category detail
 */
export const saveCategoryAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/clientApp/categories/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}).catch(
            err => message.error('category detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving category
 */


/**
 * To get category list
 */
export const getCategoriesAPI = async () => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/clientApp/categories/`,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('category detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting category list
 */



/**
 * To save the item detail
 */
export const saveItemAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/clientApp/items/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('items detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving items
 */


/**
 * To update the item detail
 */
export const updateItemAPI = async (id, data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("token")
    const response = await API.put(`api/v1/${tenantName}/clientApp/items/${id}/`, data,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('item detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving items
 */


/**
 * To get item list
 */
export const getItemsAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/clientApp/items/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('item detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting item list
 */


/**
 * To get sales filter by their status
 */
export const getSalesWithStatusApi = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/clientApp/items/`, { data },
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('item detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting item list
 */

/**
 * To save the quotation detail
 */
export const saveQuotationAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/saleApp/quotations/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}).catch(
            err => message.error('quotation detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving quotation
 */


/**
 * To get quotation 
 */
export const getQuotationsAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/saleApp/quotations/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('item detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting item list
 */



/**
 * To save the delivery challan detail
 */
export const saveDeliveryChallanAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/saleApp/delivery-challans/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('delivery-challan detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving delivery challan
 */


/**
 * To get delivery challan 
 */
export const getDeliveryChallansAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/saleApp/delivery-challans/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('item detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting delivery challan
 */

/**
 * To get the PayemntIn detail
 */
export const getPendingSalesApi = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/saleApp/sales/`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        params: data,
    }).catch(
        err => message.error('party failed.')
    )
    return response ? response.data : {}
}
/**
 * End of getting parties
 */

/**
 * To get the PayemntOut detail
 */

export const getPendingPurchaseApi = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/purchaseApp/purchases/`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        params: data,
    }).catch(
        err => message.error('party failed.')
    )
    return response ? response.data : {}
}
/**
 * End of getting  PayemntOut
 */



/**
 * To save the purchaseOrder detail
 */
export const savePurchaseOrderAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/purchaseApp/purchase-orders/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('purchaseOrder detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving purchaseOrder
 */


/**
 * To get purchaseOrder 
 */
export const getPurchaseOrderAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/purchaseApp/purchase-orders/`,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('item detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting item list
 */


/**
 * To get Party ledgers 
 */
export const getLedgersAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/ledgerApp/ledgers/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('ledger detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting ledger list
 */

/**
 * To get Item Wise Report 
 */
export const getItemsWiseReportAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/reportApp/item-wise-report/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('item wise report detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting ledger list
 */


/**
 * To get dashboard data
 */
export const getDashboardAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/dashboardApp/dashboard/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('dashboard detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting dashbaord data
 */


/**
 * To sale the unit detail
 */
export const saveUnitAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/clientApp/units/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('unit detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving unit
 */


/**
 * To get unit list
 */
export const getUnitsAPI = async () => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/clientApp/units/`,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('unit detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting unit list
 */


/**
 * To get day book list
 */
export const getDayBooksAPI = async () => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/reportApp/day-book/`,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('unit detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting unit list
 */


/**
 * To get expense list
 */
export const getExpenseAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/purchaseApp/expenses/`,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        params: data
    }).catch(
            err => message.error('expense detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of expenses list
 */


/**
 * To get payments list
 */
export const getPaymentReportsAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/reportApp/payment-report/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
            err => message.error('cash bank report detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting payments
 */


/**
 * save the Tcs Details
 */
export const saveTcsDetailAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/ledgerApp/tcs-tax-rates/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('TcsDetails registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving Tcs Details
 */


/**
 * get the Tcs Details
 */
export const getTcsDetailAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/ledgerApp/tcs-tax-rates/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('TcsDetails registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting Tcs Details
 */



/**
 * save the Tds Details
 */
export const saveTdsDetailAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/ledgerApp/tds-tax-rates/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('TdsDetails registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of saving Tds Details
 */


/**
 * get the Tds Details
 */
export const getTdsDetailAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/ledgerApp/tds-tax-rates/`, formData,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`} }).catch(
            err => message.error('TdsDetails registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting Tds Details
 */

/**
 * get the Tds Payable
 */
export const getTdsPyableAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/reportApp/tds-payable/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: data
        }).catch(
             err => message.error('TdsPayable registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting Tds Payable
 */

/**
 * get the Tcs Payable
 */
export const getTcsPyableAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/reportApp/tcs-payable/`, data,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
          params: data }).catch(
            err => message.error('TcsPayable registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting Tcs Payable
 */


/**
 * get the Tds Receivable
 */
export const getTdsReceivableAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/reportApp/tds-receivable/`, data,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
          params: data
         }).catch(
            err => message.error('TcsReceivable registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting Tds Receivable
 */

/**
 * get the Tcs Receivable
 */
export const getTcsReceivableAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/reportApp/tcs-receivable/`, data,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
          params: data
        }).catch(
            err => message.error('TcsReceivable registration failed.')
        )
    return response ? response.data : {}
}
/**
 * End of getting Tcs Receivable
 */


/** 
 * To get expense-category 
 */
export const getExpenseCategoriesAPI = async (data) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.get(`api/v1/${tenantName}/purchaseApp/expense-category/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            params: { data }
        }).catch(err => message.error('Expense detail failed.'))
    return response ? response.data : {}
}

/** 
 * End of getting expenses-category 
 */

/** 
* To save expense-category detail 
*/
export const saveExpenseCategoryAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.post(`api/v1/${tenantName}/purchaseApp/expense-category/`, formData, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
    }).catch(err => message.error('Expense detail failed.'))
    return response ? response.data : {}
}

/** 
 * End of saving expense-category
 */

/**
* To save expense
*/
export const saveExpenseAPI = async (formData) => {
    const tenantName = secureLocalStorage.getItem("tenant");
    const token = secureLocalStorage.getItem("access");

    const response = await API.post(`api/v1/${tenantName}/purchaseApp/expenses/`, formData, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
    }).catch(err => {
        message.error('Expense save failed.');
    });

    return response ? response.data : {};
};
/** 
 * End of saving expense
 */



/**
 * To delete PaymentIn
 */
export const deletePaymentInAPI = async (id) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.delete(`api/v1/${tenantName}/saleApp/payment-ins/${id}/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        }).catch(
            err => message.error('PaymentIn detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of delete PaymentIn
 */


/**
 * To delete PaymentOut
 */
export const deletePaymentOutAPI = async (id) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.delete(`api/v1/${tenantName}/purchaseApp/payment-outs/${id}/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        }).catch(
            err => message.error('PaymentOut detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of delete PaymentOut
 */


/**
 * To delete delieveryChallan
 */
export const deleteDeliveryChallanAPI = async (id) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.delete(`api/v1/${tenantName}/saleApp/delivery-challans/${id}/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        }).catch(
            err => message.error('deliveryChallan detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of delete delieveryChallan
 */

/**
 * To delete Quotation
 */
export const deleteQuotationAPI = async (id) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.delete(`api/v1/${tenantName}/saleApp/quotations/${id}/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        }).catch(
            err => message.error('Quotation detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of delete Quotation
 */


/**
 * To delete PurchaseOrder
 */
export const deletePurchaseOrderAPI = async (id) => {
    const tenantName = secureLocalStorage.getItem("tenant")
    const token = secureLocalStorage.getItem("access")
    const response = await API.delete(`api/v1/${tenantName}/purchaseApp/purchase-orders/${id}/`,
        {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        }).catch(
            err => message.error('PurchaseOrder detail failed.')
        )
    return response ? response.data : {}
}
/**
 * End of delete PurchaseOrder
 */
