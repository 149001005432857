import React, { useEffect } from 'react'
import PurchaseDashboardTable from './PurchaseDashboardTable';
import { useDispatch, useSelector } from 'react-redux';
import { deletePurchase, getParties } from './actions';
import { useNavigate } from 'react-router-dom';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';


export default function PurchaseList({ purchase, handleTableChange }) {

  const dispatch = useDispatch()

  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Purchase Invoice Number', accessor: 'purchase_inv_no' },
    { Header: 'Party Name', accessor: 'billed_to_company_name' },
    // { Header: 'Due In', accessor: 'dueIn' },
    { Header: 'Amount', accessor: 'total_amount' },
    { Header: 'Balance Amount', accessor: 'balance_amount' },
    { Header: 'Status', accessor: 'payment_status' },
    // { Header: 'Current Balance', accessor: 'current_balance' },
  ];

  const navigate = useNavigate();

  // Function to handle navigation
  const handleView = (record) => {
    navigate("/invoice2/print-dashboard", { state: { record } });
  };

  const handleDelete = async (data) => {
    if (data?.id) {
      const response = await dispatch(deletePurchase(data?.id))
    } else {
      message.error("Please Select Data")
    }
  }


  // Convert columns to the format expected by Ant Design Table
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    sorter: ["total_amount", 'purchase_inv_no', 'balance_amount', 'date'].includes(column.accessor),
    ellipsis: true,
  }));

  // Add an action column
  tableColumns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            <EyeOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
        {/* <Tooltip title="Edit" color="blue">
          <button className="p-1 mx-0.5 bg-blue-500 rounded" >
            <EditOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
        {/* <Tooltip title="Delete" color="red"> */}
        {/* <button className="p-1 mx-0.5 bg-red-500 rounded" onClick={() => handleDelete(record)}> */}
        <Popconfirm
          placement="topRight"
          title='object delete'
          description='Are you sure want to delete'
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button disabled={record?.payment_status === 'paid' ? true : false} className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-black p-0 m-0" />
          </Button>
        </Popconfirm>
        {/* </button> */}
        {/* </Tooltip> */}
      </span>
    ),
  });



  // Create data dynamically
  // const data = response?.map((item) => {
  //   return {
  //     company_name: item.company_name,
  //     mobile_no: item.mobile_no,
  //     state: item.state,
  //     city: item.city,
  //     gst_no: item.gst_no,
  //     opening_balance: item.opening_balance,
  //     current_balance: item.current_balance,
  //   };
  // });


  return (
    <div>
      <PurchaseDashboardTable
        columns={columns}
        data={purchase}
        tableColumns={tableColumns}
        handleTableChange={handleTableChange}
      />
    </div>
  )
}
