import React, { useState, useEffect } from 'react';
import { Divider, Input, Checkbox, message } from 'antd';

const { Search } = Input;

const PayementOutTable = ({ invoices, setInvoices, paymentAmount, setPaymentAmount, totalRemainingAmount, setTotalRemainingAmount }) => {
    const [searchText, setSearchText] = useState('');


    // const filteredSales = pendingSales.map((sale,id) => ({
    //             key : (id+1).toString(),
    //             InvoiceNumber : (id+1).toString(),
    //             Date: sale.date,
    //             InvoiceAmount: sale.balance_amount,
    //             RemainingAmount: sale.balance_amount,
    //             checked: false,
    //             disabled: true,
    //             InvoiceSettled:0
    //     }))

    useEffect(() => {
        let amountToBeSettled = paymentAmount;

        if (paymentAmount > 0) {

            const updatedInvoices = invoices.map((invoice) => {
                if (amountToBeSettled >= invoice.balance_amount) {
                    amountToBeSettled -= parseFloat(invoice.balance_amount).toFixed(2);
                    return { ...invoice, settled_amount: parseFloat(invoice.balance_amount).toFixed(2), checked: true };
                } else if (amountToBeSettled > 0) {
                    const settled = parseFloat(amountToBeSettled).toFixed(2);
                    amountToBeSettled = 0;
                    return { ...invoice, settled_amount: settled, checked: true };
                } else {
                    return { ...invoice, settled_amount: parseFloat(0).toFixed(2), checked: false };
                }
            });
            setTotalRemainingAmount(parseFloat(amountToBeSettled).toFixed(2));
            setInvoices(updatedInvoices);
        }
    }, [paymentAmount]);

    const handleCheckboxChange = (index) => {
        const updatedInvoices = [...invoices];
        let newRemainingAmount = totalRemainingAmount;

        if (newRemainingAmount <= 0 && !updatedInvoices[index].checked) {
            message.error("You can't check more than settled");
            return;
        }

        updatedInvoices[index].checked = !updatedInvoices[index].checked;

        if (!updatedInvoices[index].checked) {
            newRemainingAmount += parseFloat(updatedInvoices[index].settled_amount);
            updatedInvoices[index].settled_amount = parseFloat(0).toFixed(2);
        } else {
            if (newRemainingAmount >= updatedInvoices[index].balance_amount) {
                newRemainingAmount -= parseFloat(updatedInvoices[index].balance_amount);
                updatedInvoices[index].settled_amount = parseFloat(updatedInvoices[index].balance_amount).toFixed(2);
            } else {
                updatedInvoices[index].settled_amount = parseFloat(newRemainingAmount).toFixed(2);
                newRemainingAmount = parseFloat(0);
            }
        }

        setTotalRemainingAmount(parseFloat(newRemainingAmount).toFixed(2));
        setInvoices(updatedInvoices);
    };


    return (
        <div>
            <Divider />
            <div className="flex justify-between items-center">
                <h4>Settle invoices with this payment</h4>
                <Search
                    placeholder="Search Invoice Number"
                    onSearch={(value) => setSearchText(value)}
                    className="w-52"
                />
            </div>
            <div className="mt-4">
                <table className="table-auto text-center text-sm border-collapse border border-gray-200 w-full mt-8">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="border border-gray-300 px-4 py-2"></th>
                            <th className="border border-gray-300 px-4 py-2">Date</th>
                            <th className="border border-gray-300 px-4 py-2">Invoice Number</th>
                            <th className="border border-gray-300 px-4 py-2">Total Amount</th>
                            <th className="border border-gray-300 px-4 py-2">Amount Settled</th>
                            <th className="border border-gray-300 px-4 py-2">Remaining Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((item, index) => (
                            <tr key={item.key}>
                                <td className="border border-gray-300 px-4 py-2">
                                    <Checkbox
                                        checked={item.checked}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                    {item.id}
                                </td>
                                <td className="border border-gray-300 px-4 py-2">{item.date}</td>
                                <td className="border border-gray-300 px-4 py-2">{item.invoice_no}</td>
                                <td className="border border-gray-300 px-4 py-2">{item.total_amount} ({item.balance_amount})</td>
                                <td className="border border-gray-300 px-4 py-2">{item.settled_amount}</td>
                                <td className="border border-gray-300 px-4 py-2">{item.balance_amount - item.settled_amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PayementOutTable;
