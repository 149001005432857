import React, { useEffect, useState } from 'react';
import { Card, Button, Select, Input, Table, Tag } from 'antd';
import { CheckOutlined, WarningOutlined, ShoppingCartOutlined, UnorderedListOutlined, DisconnectOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSales } from './actions';
import SaleList from './SaleList';
import * as XLSX from 'xlsx';

const { Option } = Select;
const { Column } = Table;
const { Search } = Input;

export default function SalesDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector(state => state.sale.getSales.loading);
    const error = useSelector(state => state.sale.getSales.error);
    const salesData = useSelector(state => state.sale.getSales.response);
    const saleDeleteResponse = useSelector(state => state.sale.deleteSale.response);

    const [saleSearchText, setSaleSearchText] = useState('')   // state for set the searched value

    const [filterQuery, setFilterQuery] = useState({ payment_status: 'unpaid_or_partial_paid' })                                                           // state for filter query
    const [salesDetail, setSalesDetail] = useState({
        totalSales: 0,
        totalPaidAmount: 0,
        totalUnPaidAmount: 0
    });

    console.log(saleDeleteResponse, 'saleDeleteResponse-----------------------------------')

    useEffect(() => {
        getSalesData();
    }, [dispatch, saleSearchText, filterQuery, saleDeleteResponse]);

    const getSalesData = async () => {
        if (saleSearchText || filterQuery) {
            // await dispatch(getSales({ search: saleSearchText }));
            const response = await dispatch(getSales(filterQuery));
            if (response) {
                setSalesDetail({
                    ...salesDetail,
                    totalSales: response.total_sales,
                    totalPaidAmount: response.total_paid_amount,
                    totalUnPaidAmount: response.total_unpaid_amount,
                });
            }
        }

        // else if (filterQuery) {
        //     const response = await dispatch(getSales(filterQuery));
        //     if (response) {
        //         setSalesDetail({
        //             ...salesDetail,
        //             totalSales: response.total_sales,
        //             totalPaidAmount: response.total_paid_amount,
        //             totalUnPaidAmount: response.total_unpaid_amount,
        //         });
        //     }
        // }

        else {
            const response = await dispatch(getSales());

            if (response) {
                setSalesDetail({
                    ...salesDetail,
                    totalSales: response.total_sales,
                    totalPaidAmount: response.total_paid_amount,
                    totalUnPaidAmount: response.total_unpaid_amount,
                });
            }
        }
    };


    const onCreateSalesInvoice = () => {
        navigate('/sales-form');
    };

    const handlePrint = () => {
        console.log(salesData);
        const dt = salesData?.sales?.results
        navigate('/sale-invoice-bill/print', { state: { salesData: dt } });
    };

    const handleSaleSearch = (value) => {
        setSaleSearchText(value)
        setFilterQuery({
            ...filterQuery,
            search: value
        })
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        const wsData = salesData?.sales?.results.map((e, index) => [
            index + 1,
            e.date, // Replace with correct date format if necessary
            e.sale_inv_no,
            e.billed_to,
            e.total_amount,
            e.balance_amount,
            e.payment_status,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Sr. No',
                'Date',
                'Sale Invoice Number',
                'Billed To',
                'Total Amount',
                'Balance Amount',
                'Payment Status',
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');
        XLSX.writeFile(workbook, 'sales.xlsx');
    };

    return (
        <div className="">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold">Sales Invoices</h1>
                <div className="flex items-center space-x-2">
                    <Button className="bg-primary text-primary-foreground" onClick={() => navigate('/reports/sales-report')}>Reports</Button>
                    <Button className="bg-secondary text-secondary-foreground">Settings</Button>
                </div>
            </div>


            <div>
                <div className="grid grid-cols-3 gap-4 mb-4">
                    <Card className="border border-blue-500 bg-blue-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><UnorderedListOutlined className="mr-2" />Total Sales</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {salesDetail?.totalSales}</p>}
                        />
                    </Card>
                    <Card className="border border-green-500 bg-green-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><CheckOutlined className="mr-2" />Paid</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {salesDetail?.totalPaidAmount}</p>}
                        />
                    </Card>
                    <Card className="border border-red-500 bg-red-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><DisconnectOutlined className="mr-2" />Unpaid</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {salesDetail?.totalUnPaidAmount}</p>}
                        />
                    </Card>
                </div>

                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center space-x-2">
                        <Search
                            placeholder="Search here"
                            allowClear
                            onSearch={handleSaleSearch}
                            className="w-full"
                        />
                    </div>
                    <Button className="ml-auto bg-[#a854f9] text-white" onClick={handlePrint}>
                        <b>Print</b>
                    </Button>
                    <Button onClick={handleExportToExcel} className='ml-2 bg-white-100 text-blue-500 border-blue-500'>
                        <b>Excel</b>
                        <DownloadOutlined />
                    </Button>
                    {/* <Button type="dashed" icon={<ShoppingCartOutlined />} className="ml-2">
                        Bulk Download
                    </Button> */}
                    <Button className="ml-2 bg-violet-600 text-white" onClick={onCreateSalesInvoice}>
                        <b>Create Sale Invoice</b>
                    </Button>
                </div>

                <div className="overflow-x-auto">
                    <SaleList
                        sale={salesData?.sales?.results}
                        handleTableChange={handleTableChange}
                    />
                </div>
            </div>
        </div>
    );
}
