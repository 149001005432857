import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Radio, Card, Select, Modal, message, Divider } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getItems, saveItem, updateItems } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import UnitForm from '../unit/UnitForm';
import { getCategories } from '../category/actions';
import { getUnits } from '../unit/actions';
import CategoryContained from '../category/CategoryContained';
import UnitContained from '../unit/UnitContained';

export default function ItemForm() {

  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isUnitModalVisible, setIsUnitModalVisible] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [units, setUnits] = useState(null);
  const [isGstApplicable, setIsGstApplicable] = useState(true)
  const [initialValues, setInitialValues] = useState({ opening_qty: 0, opening_rate: 0 })
  const [apiError, setApiError] = useState(''); // State for API error

  const dispatch = useDispatch()
  const categoryResponse = useSelector(state => state.categories.getCategories.response)
  const unitResponse = useSelector(state => state.units.getUnits.response)

  const location = useLocation();

  const [form] = Form.useForm()

  const navigate = useNavigate()

  const handleUnitChange = (value) => {
    // if (value === "") {
    //   showModal();
    // }
    setSelectedUnit(value);
  };

  useEffect(() => {
    if (location.state?.record) {
      setInitialValues(location.state?.record)
    }
    // When initialValues are set, update the form fields
    if (location.state?.record) {
      form.setFieldsValue(location.state?.record);
    }
    if (categoryResponse) {
    } else {
      dispatch(getCategories())
    }
    if (!unitResponse) {
      dispatch(getUnits())
    }
  }, [dispatch, location.state?.record, form, categoryResponse, unitResponse]);


  const handleSave = async (values) => {
    setApiError(''); // Reset error state
    try {
      if (!location.state) {
        values['current_qty'] = values['opening_qty'];
        values['current_rate'] = values['opening_rate'];
        const response = await dispatch(saveItem(values));
        if (response?.success) {
          navigate('/product/item');
        } else {
          throw new Error(response?.error || 'Failed to save item'); // Handle error
        }
      } else if (location.state?.record?.id) {
        const response = await dispatch(updateItems(location.state.record.id, values));
        if (response?.success) {
          navigate('/product/item');
        } else {
          throw new Error(response?.error || 'Failed to update item'); // Handle error
        }
      } else {
        message.error("Please Try again");
      }
    } catch (error) {
      setApiError(error.message); // Set error message
      message.error(error.message); // Display error message
    }
  };

  
  // Custom validator for integer fields
  const validateInteger = (_, value) => {
    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Only integers are allowed.');
  };


  const handleChangeApplicable = (value) => {
    setIsGstApplicable(value.target.value)
    if (value.target.value == false) {
      form.setFieldsValue({ gst_percentage: 0 })
    }
  }

  return (
    <div className="">
      {isCategoryModalVisible &&
        <Modal
          visible={isCategoryModalVisible}
          onCancel={() => setIsCategoryModalVisible(false)}
          onOk={() => setIsCategoryModalVisible(false)}
          title="Category"
          footer={null}
        >
          <CategoryContained />
        </Modal>
      }

      {isUnitModalVisible &&
        <Modal
          visible={isUnitModalVisible}
          onCancel={() => setIsUnitModalVisible(false)}
          onOk={() => setIsUnitModalVisible(false)}
          title="Unit"
          footer={null}
        >
          <UnitContained />
        </Modal>
      }
      <Form
        form={form}
        layout="vertical"
        name="partyForm"
        // initialValues={{ opening_qty: 0, opening_rate: 0 }}
        onFinish={handleSave}
        initialValues={initialValues}
      >
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-semibold">Create New Item</h2>
          <div className="flex space-x-2">
            <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
              <b>Save</b>
            </Button>
            <Button
              htmlType="button"
              className="shadow-inner"
              onClick={() => navigate(-1)}
            >
              <b>Cancel</b>
            </Button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <Card title="Item Details" size="small" className="w-full md:w-1/2 mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item
                name="item_name"
                label="Item Name"
                rules={[
                  { required: true, message: 'Please enter item name' },
                  { max: 50, message: 'Item name must be at most 50 characters' },
                ]}
              >
                <Input placeholder="Item name" maxLength={50} className='shadow-inner' />
              </Form.Item>

              <Form.Item
                name="category"
                label="Category "
                rules={[
                  { required: true, message: 'Please select GST percentage' },
                ]}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  placeholder="Select Category List"
                  className='shadow-inner'
                  dropdownRender={menu => (
                    <>
                      <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                        <Link
                          onClick={() => setIsCategoryModalVisible(!isCategoryModalVisible)}
                          className='flex ml-auto text-blue-500'
                        >
                          New Category
                        </Link>
                      </div>
                      <Divider style={{ margin: '0px 0' }} />
                      {menu}
                    </>
                  )}
                >
                  {categoryResponse?.map((dt) => (
                    <Select.Option value={dt.id} key={dt.id}>{dt.category_name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="brand"
                label="Brand"
                rules={[
                  { required: true, message: 'Please enter brand' },
                  { max: 50, message: 'Brand must be at most 50 characters' },
                ]}
              >
                <Input placeholder="Brand" maxLength={50} className='shadow-inner' />
              </Form.Item>

              <Form.Item
                name="model_no"
                label="Model Number"
                rules={[
                  { required: true, message: 'Please enter model number' },
                  // { max: 10, message: 'Model number must be at most 10 characters' },
                  // { validator: validateInteger },
                ]}
              >
                <Input placeholder="Model number" maxLength={50} className='shadow-inner' />
              </Form.Item>

              <Form.Item
                name="hsn_code"
                label="HSN Code"
                rules={[
                  { required: true, message: 'Please enter the HSN code' },
                  { max: 50, message: 'HSN code must be at most 50 characters' },
                  { validator: validateInteger },
                ]}
              >
                <Input placeholder="Enter HSN code" maxLength={50} className='shadow-inner' />
              </Form.Item>
            </div>
          </Card>

          <Card title="Tax Details" size="small" className="w-full md:w-1/2 mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Form.Item
                name="gst_applicable"
                label="GST Applicable"
                initialValue={true}
              >
                <Radio.Group onChange={handleChangeApplicable} className='shadow-inner'>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="gst_percentage"
                label="GST Percentage"
                rules={[
                  { required: true, message: 'Please select GST percentage' },
                ]}
              >
                <Select
                  disabled={!isGstApplicable}
                  placeholder="Select GST percentage"
                  className='shadow-inner'
                  optionFilterProp="children"
                  showSearch
                >
                  <Select.Option value={0}>0%</Select.Option>
                  <Select.Option value={5}>5%</Select.Option>
                  <Select.Option value={12}>12%</Select.Option>
                  <Select.Option value={18}>18%</Select.Option>
                  <Select.Option value={28}>28%</Select.Option>
                </Select>
              </Form.Item>


              <Form.Item
  name="opening_qty"
  label="Opening Quantity"
  rules={[
    { required: true, message: 'Please enter the quantity rate' },
    { pattern: /^(\d+(\.\d{1,2})?)?$/, message: 'Enter a valid opening quantity' }
  ]}
>
  <Input
    onChange={(e) => {
      const value = e.target.value;

      // Allow only numbers and a single decimal point
      const validValue = value.replace(/[^0-9.]/g, '');

      // Prevent more than one decimal point
      if ((validValue.match(/\./g) || []).length <= 1) {
        // Check for max length (10 characters) and max 2 decimal places
        const parts = validValue.split('.');
        if (validValue.length <= 10 &&
            (parts.length < 2 || parts[1].length <= 2)) {
          // Set the valid value in the form
          form.setFieldsValue({ opening_qty: validValue }); // Ensure 'form' is defined in your component
        }
      }
    }}
    // maxLength={10}
  />
</Form.Item>


              <Form.Item
                name="unit"
                label="Unit"
                rules={[{ required: true, message: 'Please select Unit' }]}
              >
                <Select
                  placeholder="Select Unit"
                  className='shadow-inner'
                  onChange={handleUnitChange}
                  value={selectedUnit}
                  showSearch
                  optionFilterProp='children'
                  dropdownRender={menu => (
                    <>
                      <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                        <Link
                          onClick={() => setIsUnitModalVisible(!isUnitModalVisible)}
                          className='flex ml-auto text-blue-500'
                        >
                          New Unit
                        </Link>
                      </div>
                      <Divider style={{ margin: '0px 0' }} />
                      {menu}
                    </>
                  )}
                >
                  {/* <Select.Option value=''>Add Unit</Select.Option> */}
                  {unitResponse?.map((dt) => (
                    <Select.Option value={dt.id} key={dt.id}>{dt.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* <Modal
                title="Add Unit"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                <Form onFinish={handleAddUnit}>
                  <UnitForm />
                </Form>
              </Modal> */}

              <Form.Item
                name="opening_rate"
                label="Opening Rate"
                rules={[
                  { required: true, message: 'Please enter the opening rate' },
                  {
                    validator: (_, value) => {
                      const isNumber = /^[0-9]*\.?[0-9]+$/.test(value); // regex for numbers, including decimals
                      if (value !== undefined && value !== null && !isNumber) {
                        return Promise.reject(new Error('Opening rate must be a numeric value'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                
              >
                <Input placeholder="Enter opening rate" maxLength={50} className='shadow-inner' />
              </Form.Item>
            </div>
          </Card>
        </div>
      </Form>
    </div>
  );
}
