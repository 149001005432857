import React from 'react';
import logo from '../../../images/logo.png';
import { Button, Col, Table, Row, Divider } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
export default function Invoice() {
    const location = useLocation();
    const record = location.state?.record || {};
    const user = JSON.parse(secureLocalStorage.getItem("loggedInUser")) || {};

    const printInvoice = () => {
        window.print();
    };

    const columns = [
        { title: 'ITEM', dataIndex: 'item', key: 'item' },
        { title: 'QTY', dataIndex: 'qty', key: 'qty' },
        { title: 'RATE', dataIndex: 'rate', key: 'rate' },
        { title: 'DISCOUNT', dataIndex: 'discount', key: 'discount' },
        { title: 'GST', dataIndex: 'gst_amount', key: 'gst_amount' },
        { title: 'AMOUNT', dataIndex: 'amount', key: 'amount' },
    ];

    return (
        <Content className="p-4 bg-white shadow-lg rounded-lg">
            <div className="invoice-content">
                <div className="overflow-auto w-full m-auto px-4">
                    <div className="bg-gray-200 w-full flex items-center justify-between p-2">
                        <img
                            src={user.company_logo || logo}
                            alt="Logo"
                            className="h-20"
                        />
                        <h2 className="font-semibold text-2xl text-center mx-auto">Purchase Order Invoice</h2>
                    </div>

                    <div className="py-2">
                        <Row gutter={16}>
                            <Col span={13}>
                                <div className="font-semibold">Purchase Order Number: 
                                    <span className="font-normal"> {record?.purchase_order_no}</span>
                                </div>
                            </Col>
                            <Col span={11}>
                                <div className="font-semibold">Date: 
                                    <span className="font-normal"> {record.date}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Divider />

                    <div className="flex flex-col sm:flex-row justify-between mt-2">
                        <div className="w-full sm:w-[45%]">
                            <div className="bg-gray-300 font-semibold px-2 py-1">Billed From</div>
                            <Divider />
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="font-semibold">Company Name:</td>
                                        <td>{user.company_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Party Name:</td>
                                        <td>{user?.acc_holder_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Address:</td>
                                        <td>{user?.address}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">City:</td>
                                        <td>{user?.city}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">State:</td>
                                        <td>{user.state}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Zip Code:</td>
                                        <td>{record?.billed_to_zip_code || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Phone:</td>
                                        <td>{user?.mobile_number}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">GST:</td>
                                        <td>{user?.gst}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="w-full sm:w-[45%]">
                            <div className="bg-gray-300 font-semibold px-2 py-1">Billed To</div>
                            <Divider />

                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="font-semibold">Company Name:</td>
                                        <td>{record?.billed_to_company_name || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Party Name:</td>
                                        <td>{record?.billed_to_party_full_name || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Address:</td>
                                        <td>{record?.billed_to_address || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">City:</td>
                                        <td>{record?.billed_to_city || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">State:</td>
                                        <td>{record?.billed_to_state || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Zip Code:</td>
                                        <td>{record?.billed_to_zip_code || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">Phone:</td>
                                        <td>{record?.billed_to_mobile_no || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-semibold">GST:</td>
                                        <td>{record?.billed_to_gst_no || 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={record?.purchase_order_items || []}
                        pagination={false}
                        className="rounded-lg border border-gray-300 mb-4"
                        size="small"
                        rowClassName="hover:bg-gray-50"
                    />

                    <Row>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <table className="table-auto w-full text-left">
                                <tbody>
                                    <tr>
                                        <td className="p-1 font-medium">TAXABLE AMOUNT</td>
                                        <td className="p-1">₹ {record?.sub_total}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-1 font-medium">GST Amount</td>
                                        <td className="p-1">₹ {record?.gst_amount}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="border-t border-gray-300 my-1"></td>
                                    </tr>
                                    <tr>
                                        <th className="p-1 font-semibold">TOTAL AMOUNT</th>
                                        <th className="p-1 font-semibold">₹ {record?.total_amount}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <div className="w-full flex my-4 justify-center">
                        <Button
                            type="primary"
                            onClick={printInvoice}
                            className="block mx-auto print-button"
                        >
                            Print Invoice
                        </Button>
                    </div>
                </div>

                <style>
                    {`
                    @media print {
                        body * {
                            visibility: hidden;
                        }
                        .invoice-content, .invoice-content * {
                            visibility: visible;
                        }
                        .invoice-content {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                        }
                        .ant-table {
                            display: block !important;
                            visibility: visible !important;
                        }
                        .print-button {
                            display: none !important; /* Hide the print button during print */
                        }
                    }
                    `}
                </style>
            </div>
        </Content>
    );
}
